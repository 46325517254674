import axios from 'axios';
import React, { useState } from 'react';
import Header from '../../header/Header';
import BasicScreeningList from './BasicScreeningList';
// import "https://activityboard.symstech.com/css/components/forms.css";
//  @params name, email, contactNo, fatherName, address, idType, idNo
const BasicScreeningManager = () => {

    //  comp level states
    const [question, setQuestion] = useState("");
    const [qualifyingAns, setQualifyingAns] = useState("");
    const [quesDescription, setQuesDescription] = useState("");

    // submit the form
    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/basicscreening`, {
            question,
            quesDescription,
            qualifyingAns
        });
        if (!res) {
            console.log("Error");
        }
        alert("Success");
        setQuestion("");
        setQualifyingAns("");
        setQuesDescription("");
    }


    return (
        <>
            <div className="rightpanel" style={{ position: "relative", marginLeft: "0px" }}>
                <div className="pageheader">
                    <div className="pageicon"><span className="fa fa-sliders"></span></div>
                    <div className="pagetitle">
                        <h5>Gee Gee Corp Legal // Test pro</h5>
                        <h1>Milestones</h1>
                    </div>
                </div>
            </div>







            {/* <div className='container basic-screening-manager-container'>
            <h1>Basic Screening Manager</h1>
            <form 
           >
                   

                    <label htmlFor="qualifying ans">
                        Qualifying ans :
                        <input required
                            value={qualifyingAns}
                            onChange={(e) => setQualifyingAns(e.target.value)}
                            type="text"/>
                    </label>

                    <label htmlFor="question description">
                        Question description :
                        <input required
                            value={quesDescription}
                            onChange={(e) => setQuesDescription(e.target.value)}
                            type="text" />
                    </label>
                <button
                    type="submit"
                    className="btn btn-success">
                    Add
                </button>
            </form> */}
















            <div className="maincontent">
                <div className="maincontentinner">

                    <h4 className="widgettitle title-light"><i className="fa fa-rocket"></i> Milestone </h4>


                    <form className="formModal" method="post" action="https://activityboard.symstech.com/tickets/editMilestone/" style={{ minWidth: "250px" }} onSubmit={handleFormSubmit} >

                        <label htmlFor="question">Question :</label>
                        <input type="text" name="headline" value={question} onChange={(e) => setQuestion(e.target.value)}
                            style={{ width: "100%" }} /><br />


                        <label htmlFor="qualifying ans">Qualifying ans :</label>
                        <input type="text" name="headline" value={qualifyingAns}
                            onChange={(e) => setQualifyingAns(e.target.value)} style={{ width: "100%" }} /><br />

                        <label htmlFor="question description">Question description :</label>
                        <textarea type="text" name="headline" value={quesDescription}
                            onChange={(e) => setQuesDescription(e.target.value)} style={{ width: "100%" }} /><br />




                        <div className="row">
                            <div className="col-md-6">
                                <input type="submit" value="Add" className="btn btn-primary" />
                            </div>

                        </div>

                    </form>




                </div>
            </div>






        </>
    )
}

export default BasicScreeningManager;
