import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Header from "../../header/Header";
import $ from "jquery";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import InterPlanTable from "../../inview/InterPlanTable";

const Home = () => {
  // navigator
  const navigate = useNavigate();

  // states for plans
  // const [availInterplans, setAvailInterPlans] = useState([]);

  // const getInterviewPlans = async () => {
  //   const { data } = await axios.get(
  //     `${process.env.REACT_APP_API_ENDPOINT}/interviewplans`
  //   );
  //   // console.log(data);
  //   setAvailInterPlans(data);
  // };

  // useEffect(() => {
  //   getInterviewPlans();
  // }, []);

  const [search, setSearchFiled] = useState("");
 

  return (
    <>
      <Header
      // head="Home" icon="fa-solid fa-house"
      />

      <div className="maincontent d-flex  align-items-start  flex-wrap">
        <div className="col-md-6">
          <div className="maincontentinner shadow  d-flex flex-wrap justify-content-between align-items-center py-2">
            <div className="col-md-6 mb-3">
              <h6
                className="widgettitle rounded-2 shadow-sm title-light my-auto  fs-6 text-center d-flex justify-content-start align-items-center"
                onClick={() => navigate("createinterplan")}
                style={{
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                  cursor: "pointer",
                }}
              >
                <i className="fa-solid fa-people-roof fs-5 mx-2 px-1 ms-2"></i>
                New Interview plan{" "}
              </h6>
            </div>
            <div className="col-md-6 mb-3">
              <h6
                className="widgettitle rounded-2 shadow-sm title-light my-auto fs-6 text-center d-flex justify-content-start  align-items-center"
                onClick={() => navigate("candidateList")}
                style={{
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                  cursor: "pointer",
                }}
              >
                <i className="fa-solid fa-user-tie fs-5 mx-2 px-1 ms-2"></i>
                Candidate Database{" "}
              </h6>
            </div>
            {/* <div className="col-md-6 mb-3">

              <h6
                className="widgettitle title-light my-auto  fs-6 text-center d-flex justify-content-start align-items-center"
                style={{ fontWeight: "500", lineHeight: "1.5rem" }}
              >
              <i className="fa-solid fa-user-tie fs-5 mx-2 px-1 shadow-sm"></i>

                Job-Role Manager{" "}
              </h6>
            </div> */}
            <div className="col-md-6 ">
              <h6
                className="widgettitle rounded-2 shadow-sm title-light my-auto  fs-6 text-center d-flex justify-content-start align-items-center"
                onClick={() => navigate("resumesourceManager")}
                style={{
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                  cursor: "pointer",
                }}
              >
                <i className="fa-sharp fa-solid fa-file fs-5 mx-2 px-1  ms-2"></i>
                Resume Source Manager{" "}
              </h6>
            </div>
            <div className="col-md-6 ">
              <h6
                className="widgettitle rounded-2 shadow-sm title-light my-auto fs-6 text-center d-flex justify-content-start align-items-center"
                onClick={() => navigate("candidateManager")}
                style={{
                  fontWeight: "500",
                  lineHeight: "1.5rem",
                  cursor: "pointer",
                }}
              >
                <i className="fa-solid fa-user fs-5 mx-2 px-1  ms-2"></i>
                Candidate Manager{" "}
              </h6>
            </div>
          </div>
        </div>

        <div className="maincontentinner col-md-6 shadow">
          <form onSubmit={()=>navigate("searchCandidate/"+search)}>
            <label>Find Candidate</label>
            <div>
              <input
                value={search}
                autoComplete={"off"}
                autoFocus
                onChange={(e) => setSearchFiled(e.target.value)}
                type="text"
                placeholder="Search the candidate"
                className="w-100"
              />
              <button type="submit" id="btnSearch" className="btn btn-primary">
                Search
              </button>
            </div>
          </form>
        </div>
      </div>
      <InterPlanTable />
    </>
  );
};

export default Home;
