import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../header/Header";
import moment from "moment";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomToast from "../../CustomToast";

const CandidateList = () => {
  const navigate = useNavigate();
  const [alertShow,setAlertShow]=useState(false);
  // comp lvl state
  const [availCandidates, setAvailCandidates] = useState([]);

  const [availDepartments, setAvailDepartments] = useState([]);
  const [availJobRoles, setAvailJobRoles] = useState([]);
  const [availResumes, setAvailResumes] = useState([]);

  // for selected states
  const [departmentId, setdepartmentId] = useState("");
  const [jobRoleId, setjobRoleId] = useState("");
  const [jobPayRoleType, setjobPayRoleType] = useState();
  const [resumeCollectionId, setresumeCollectionId] = useState("");
  const [interMode, setInterMode] = useState("");
  // const [basicScreeningQuestionId, setbasicScreeningQuestionId] = useState("");
  const [intFromDate, seIntFromDate] = useState("");
  const [intToDate, setIntToDate] = useState("");
  const [interCandStatus, setCandidateStatus] = useState("");
  const [interLifeCycleStatus, setIntLifeCycle] = useState("");
  const [starPerformer,setStarPerformer] = useState("")
  const [aacceptanceLevel,setAcceptanceLevel] = useState("")
  const [acceptanceLevelIdentifier,setAcceptanceLevelIdentifier] = useState("")

  const interviewModeData = [
    {
      id:1,
      value:"",
      name:"None"
    },
    {
      id:2,
      value:"online",
      name:"Online"
    },
    {
      id:3,
      value:"offline",
      name:"Offline"
    },
    {
      id:4,
      value:"hybrid",
      name:"Hybrid"
    },
  ]
  localStorage.setItem("INTERVIEW_MODE_DATA",JSON.stringify(interviewModeData))
  const interviewModeDataLocal = JSON.parse(localStorage.getItem("INTERVIEW_MODE_DATA"))

  const cadidateStatusData = [
    {
      id:1,
      value:"",
      name:"None"
    },
    {
      id:2,
      value:"pending",
      name:"Pending"
    },
    {
      id:3,
      value:"pas",
      name:"Pass"
    },
    {
      id:4,
      value:"fail",
      name:"Fail"
    },
    {
      id:5,
      value:"onboard",
      name:"Onboarded"
    },
    {
      id:6,
      value:"fired",
      name:"Fired"
    },
  ]
  localStorage.setItem("CANDIDATE_STATUS_DATA",JSON.stringify(cadidateStatusData))
  const cadidateStatusDataLocal = JSON.parse(localStorage.getItem("CANDIDATE_STATUS_DATA"))

  // fetch departments
  const getDepartmentsAPI = async () => {
    const { data: departments } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/departments`
    );
    setAvailDepartments(departments);
    localStorage.setItem("ALL_DEPARTMENT",JSON.stringify(departments))
    // console.log(availDepartments);
  };

  const getDepartments = () =>{
    const tempDepartmentData = JSON.parse(localStorage.getItem("ALL_DEPARTMENT"))
    tempDepartmentData?.length > 0 ? setAvailDepartments(tempDepartmentData) : getDepartmentsAPI()
  }

  const getJobRolesAPI = async () => {
    const { data: jobroles } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/jobroles`
    );
    setAvailJobRoles(jobroles);
    localStorage.setItem("JOB_ROLES",JSON.stringify(jobroles))
    // console.log(jobroles);
  };

  const getJobRoles = () =>{
    const tempJobRolesData = JSON.parse(localStorage.getItem("JOB_ROLES"))
    tempJobRolesData?.length > 0 ? setAvailJobRoles(tempJobRolesData) : getJobRolesAPI()
  }

  const getresumeSourcesAPI = async () => {
    const { data: resumes } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/resumesource`
    );
    setAvailResumes(resumes);
    localStorage.setItem("RESUME_SOURCE",JSON.stringify(resumes))
    // console.log(resumes);
  };

  const getresumeSources = () =>{
    const tempResumeData = JSON.parse(localStorage.getItem("RESUME_SOURCE"))
    tempResumeData?.length > 0 ? setAvailResumes(tempResumeData) : getresumeSourcesAPI()
  }

  const getCandidates = async (e) => {
    e.preventDefault();
    const { data: candidates } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile/interviewplan`, {
        params:{
          departmentId:departmentId,
          resumeCollectionId:resumeCollectionId,
          jobRoleId:jobRoleId,
          jobPayRoleType:jobPayRoleType,
          // interPostingDate:interPostingDate,
          intToDate:intToDate,
          intFromDate:intFromDate, 
          interMode:interMode,
          interCandStatus:interCandStatus,
          interLifeCycleStatus:interLifeCycleStatus,
          aacceptanceLevel:aacceptanceLevel,
          starPerformer:starPerformer
        }
      });
    setAvailCandidates(candidates);
    console.log("candidatesData",candidates);
  };

  useEffect(() => {
    getDepartments();
    getJobRoles();
    getresumeSources();
  }, []);

const handleShowAlert=()=>{
  availCandidates?.length>0 ?toast.success("Result Found Successfully!"):toast.error("No result found")
}
  return (
    <>
      <Header
        head="Candidate DataBase"
        icon="fa-solid fa-users"
      ></Header>

      <div className="maincontent">

       {/* alert */}
{/* 
       <div id="jGrowl" className="top-right jGrowl"><div className="jGrowl-notification"></div><div className="jGrowl-notification ui-state-highlight ui-corner-all success" style={{display: "block"}}><div className="jGrowl-close">×</div><div className="jGrowl-header"></div><div className="jGrowl-message">Status successfully updated</div></div></div> */}

        <div className="maincontentinner">

          <form
            className="formModal"
            style={{ minWidth: "250px" }}
            onSubmit={getCandidates}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa-solid fa-plus"></i> Filter Candidates
                </h4>
                <input type="submit" value="Find" onClick={handleShowAlert} className="btn btn-primary" />
              
              </div>
              {/* <ToastContainer /> */}
              <CustomToast closeTime={2000}/>

            </div>

            <div className="row">
             
              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="departments">Department</label>
                {
                  <select
                    value={departmentId}
                    onChange={(e) => setdepartmentId(e.target.value)}
                    name="select-department"
                    className="span11"
                    id="departments"
                  >
                    {availDepartments.length > 0 ? (
                      <>
                        <option>None</option>
                        {availDepartments.map((dep, index) => (
                          <option value={dep.id} key={index}>
                            {dep.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                }
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="jobroles">Job role</label>
                {
                  <select
                    value={jobRoleId}
                    onChange={(e) => setjobRoleId(e.target.value)}
                    name="select-jobrole"
                    className="span11"
                    id="jobroles"
                  >
                    {availJobRoles.length > 0 ? (
                      <>
                        <option>None</option>
                        {availJobRoles.map((jrole, index) => (
                          <option value={jrole.id} key={index}>
                            {jrole.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                }
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="jobpayroles">Pay-Role Type</label>
                {
                  <select
                    value={jobPayRoleType}
                    onChange={(e) => setjobPayRoleType(e.target.value)}
                    name="select-jobpayrole"
                    className="span11"
                    id="jobpayroles"
                  >
                    <option value="">None</option>
                    <option value="internship">Internship</option>
                    <option value="contract">Contract</option>
                    <option value="permanent">Permanent</option>
                  </select>
                }
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="resumes">Resume source</label>

                {
                  <select
                    value={resumeCollectionId}
                    onChange={(e) => setresumeCollectionId(e.target.value)}
                    name="select-resume"
                    className="span11"
                    id="resumes"
                  >
                    {availResumes.length > 0 ? (
                      <>
                        <option>None</option>
                        {availResumes.map((resum, index) => (
                          <option value={resum.id} key={index}>
                            {resum.name}
                          </option>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                }
              </div>
             
              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="modes">Interview Mode</label>

                <select
                  value={interMode}
                  onChange={(e) => setInterMode(e.target.value)}
                  name="interview-mode"
                  className="span11"
                  id="modes"
                >
                  {/* <option value="">None</option>
                  <option value="online">Online</option>
                  <option value="offline">Offline</option>
                  <option value="hybrid">Hybrid</option> */}
                  {
                    interviewModeDataLocal.map((items)=>{
                      return <option key={items.id} value={items.value}>{items.name}</option>
                    })
                  }
                </select>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="modes">Candidate Status</label>

                <select
                  value={interCandStatus}
                  onChange={(e) => setCandidateStatus(e.target.value)}
                  name="candidate-status"
                  className="span11"
                  id="modes"
                >
                  {/* <option value="">None</option>
                  <option value="pending">Pending</option>
                  <option value="pass">Pass</option>
                  <option value="fail">Fail</option>
                  <option value="onboard">Onboarded</option>
                  <option value="fired">Fired</option> */}
                  {
                    cadidateStatusDataLocal.map((items)=>{
                      return <option key={items.id} value={items.value}>{items.name}</option>
                    })
                  }
                </select>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="modes">Interview Life-Cycle</label>

                <select
                  value={interLifeCycleStatus}
                  onChange={(e) => setIntLifeCycle(e.target.value)}
                  name="interview-life-cycle"
                  className="span11"
                  id="modes"
                >
                  <option value="">None</option>
                  <option value="init">Init</option>
                </select>
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="datefrom">Date From</label>

                <input
                 style={{width:"90%"}}
                  
                  value={intFromDate}
                  onChange={(e) => seIntFromDate(e.target.value)}
                  type="date"
                  className="hasDatepicker"
                  id="datefrom"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="dateto">Date To</label>
           
                <input
                 style={{width:"90%"}}
                  value={intToDate}
                  onChange={(e) => setIntToDate(e.target.value)}
                  type="date"
                  className="hasDatepicker"
                  id="dateto"
                />
              </div>

              <div className="col-lg-2 col-md-4 col-sm-6">
                  <label htmlFor="starperformer">Star Performer</label>
                  <select
                  value={starPerformer}
                  onChange={(e) => setStarPerformer(e.target.value)}
                  name="star-performer"
                  className="span11"
                  id="starPerformer"
                >
                  <option value="">None</option>
                  <option value={true}>True</option>
                  <option value={false}>False</option>
                </select>
              </div>
              
              <div className="col-lg-2 col-md-4 col-sm-6">
                <label htmlFor="acceptanceLevel">Acceptance Level</label>
                  <div className="row">
                    <div className="col-lg-6">
                    <select
                    value={acceptanceLevelIdentifier}
                    onChange={(e) => setAcceptanceLevelIdentifier(e.target.value)}
                    name="acceptance-level-identifier"
                    className="span11"
                    id="acceptanceLevelIdentifier"
                    >
                      <option value="">None</option>
                      <option value="e">{"="}</option>
                      <option value="l">{"<"}</option>
                      <option value="g">{">"}</option>
                    </select>
                  </div>
                  <div className="col-lg-6">
                  <select
                      value={aacceptanceLevel}
                      onChange={(e) => setAcceptanceLevel(e.target.value)}
                      name="acceptance-level"
                      className="span11"
                      id="acceptanceLevel"
                      >
                        <option value="">None</option>
                        <option value={0}>0</option>
                        <option value={1}>1</option>
                        <option value={2}>2</option>
                        <option value={3}>3</option>
                        <option value={4}>4</option>
                        <option value={5}>5</option>
                      </select>
                  </div>
                  </div>
              </div>
              
              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                  <label htmlFor="acceptanceLevel">Acceptance Level (greater)</label>
                  <select
                  value={aacceptanceLevel}
                  onChange={(e) => setAcceptanceLevel(e.target.value)}
                  name="acceptance-level"
                  className="span11"
                  id="acceptanceLevel"
                >
                  <option value="">None</option>
                  <option value={0}>{">=0"}</option>
                  <option value={1}>{">=1"}</option>
                  <option value={2}>{">=2"}</option>
                  <option value={3}>{">=3"}</option>
                  <option value={4}>{">=4"}</option> */}
                  {/* <option value={5}>5</option> */}
                {/* </select>
              </div> */}

              {/* <div className="col-lg-2 col-md-4 col-sm-6">
                  <label htmlFor="acceptanceLevel">Acceptance Level (lesser)</label>
                  
              </div> */}

            </div>
            
          </form>
        </div>
      </div>
      <div className="maincontent mt-0">
        <div className="maincontentinner">
          <div
            id="allTicketsTable_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="top"></div>
            <table
              id="allTicketsTable"
              className="table table-bordered display dataTable no-footer"
              style={{ width: "100% " }}
              aria-describedby="allTicketsTable_info"
            >
              <colgroup>
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
              </colgroup>
              <thead>
                <tr>
                <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="allTicketsTable"
                  >
                    #
                  </th>
                  <th
                    className="sorting"
                    tabIndex="1"
                    aria-controls="allTicketsTable"
                  >
                    Name
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="2"
                    aria-controls="allTicketsTable"
                  >
                    Address
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="3"
                    aria-controls="allTicketsTable"
                  >
                    Final Con.
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="4"
                    aria-controls="allTicketsTable"
                  >
                    Final Con. Date
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="5"
                    aria-controls="allTicketsTable"
                  >
                    Status
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="6"
                    aria-controls="allTicketsTable"
                  >
                    Life-Cycle
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="7"
                    aria-controls="allTicketsTable"
                  >
                    Peformance
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="8"
                    aria-controls="allTicketsTable"
                  >
                    Created
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="9"
                    aria-controls="allTicketsTable"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {availCandidates?.length > 0 ? (
                  <>
                    {availCandidates.map((plan, index) => (
                      <tr className="odd">
                        <td
                           className="ticketModal"> {plan?.id}
                        </td>
                        <td
                          onClick={() => navigate(`/interviewplans/${plan?.id}/${plan?.contactNo}`)} className="ticketModal"> {plan?.canName}
                        </td>
                        <td
                           className="ticketModal"> {plan?.canAddress}
                        </td>
                        <td
                           className="ticketModal"> {plan?.finalCon}
                        </td>
                        <td
                           className="ticketModal"> {plan?.finalConDate}
                        </td>
                        <td
                           className="ticketModal"> {plan?.interCandStatus}
                        </td>
                        <td
                           className="ticketModal"> {plan?.interLifeCycleStatus}
                        </td>
                        <td
                           className="ticketModal"> {plan?.starPerformer} | {plan?.aacceptanceLevel}
                        </td>
                        <td
                       className="ticketModal"> {moment(plan?.created_at) .utc() .format("ll @ LT")}
                        </td>

                        <td data-order="New">
                          <div className="ticketDropdown">
                            <a
                              href
                              className="f-left status label-info py-1 rounded-pill"
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                navigate(`/interviewplans/${plan?.id}`)
                              }
                            >
                              <span className="text">Update</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr>
                    <td>Nothing to show </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="bottom">
              <div
                className="dataTables_info"
                id="allTicketsTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 2 of 2 entries
              </div>
              <div className="dataTables_length" id="allTicketsTable_length">
                <label>
                  Show{" "}
                  <select
                    name="allTicketsTable_length"
                    aria-controls="allTicketsTable"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="allTicketsTable_paginate"
              >
                <a
                  href
                  className="paginate_button previous disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="0"
                  tabIndex="-1"
                  id="allTicketsTable_previous"
                >
                  Previous
                </a>
                <span>
                  <a
                    href
                    className="paginate_button current"
                    aria-controls="allTicketsTable"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    1
                  </a>
                </span>
                <a
                  href
                  className="paginate_button next disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="2"
                  tabIndex="-1"
                  id="allTicketsTable_next"
                >
                  Next
                </a>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CandidateList;
