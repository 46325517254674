import axios from "axios";
import Header from "../../header/Header";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import CandidateList from "./CandidateList";
//  @params name, email, contactNo, fatherName, address, idType, idNo
const CandidateManager = () => {
  //  comp level states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [address, setAddress] = useState("");
  const [idType, setIdType] = useState("");
  const [idNo, setIdNo] = useState("");
  const [updateFlag,setUpdateFlag] = useState(false)
  const [candidateManagerId,setCandidateManagerId] = useState('')
  const ref = useRef(null);
  // submit the form
  const handleFormSubmit = async (e) => {
    e.preventDefault();
    if(updateFlag===false){
      const res = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`,
        {
          name,
          email,
          contactNo,
          fatherName,
          address,
          idType,
          idNo,
        }
      );
      if (!res) {
        console.log("Error");
      }
      console.log("Success");
      setName("");
      setEmail("");
      setContactNo("");
      setAddress("");
      setFatherName("");
      setIdNo("");
      setIdType("");
    }else{
      const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`,
        {
          "id":candidateManagerId,
          name,
          email,
          contactNo,
          fatherName,
          address,
          idType,
          idNo
        }
      );
      setUpdateFlag(false)
      // window.location.reload();
    }
  };

  // CandidateList

  const navigate = useNavigate();
  // comp lvl state
  const [availCandidates, setAvailCandidates] = useState([]);

  const getCandidates = async () => {
    const { data: candidates } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`
    );
    // console.log(candidates);
    setAvailCandidates(candidates.data);
  };

  const handleUpdate = (candidateData,id,e,index) =>{
    console.log("candidate data",candidateData,id);
    let uname = candidateData[index].name;
    let ucontact =candidateData[index].contactNo;
    let uemail = candidateData[index].email;
    let ufatherName = candidateData[index].fatherName;
    let uadress = candidateData[index].address;
    let uIdNo = candidateData[index].idNo;
    let uIdType = candidateData[index].idType;
    setName(uname)
    setContactNo(ucontact)
    setEmail(uemail)
    setFatherName(ufatherName)
    setAddress(uadress)
    setIdNo(uIdNo)
    setIdType(uIdType)
    setUpdateFlag(true)
    setCandidateManagerId(id)
    if(e.keyCode === 13){
      uname = candidateData[index].name;
      ucontact =candidateData[index].contactNo;
      uemail = candidateData[index].email;
      ufatherName = candidateData[index].fatherName;
      uadress = candidateData[index].address;
      uIdNo = candidateData[index].idNo;
      uIdType = candidateData[index].idType;
      setName(uname)
      setContactNo(ucontact)
      setEmail(uemail)
      setFatherName(ufatherName)
      setAddress(uadress)
      setIdNo(uIdNo)
      setIdType(uIdType)
    }
    ref.current.focus();
  }

  useEffect(() => {
    getCandidates();
  }, []);


  return (
    <>
      <Header head="Candidate Manager" icon="fa-solid fa-user" />
      <div className="maincontent">
        <div className="maincontentinner">
          <form
            className="formModal"
            method="post"
            style={{ minWidth: "250px" }}
            onSubmit={handleFormSubmit}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa-solid fa-square-plus"></i> Candidate{" "}
                </h4>
                <input type="submit" value={`${updateFlag ? 'Update' : 'Add'}`} className="btn btn-primary" />
              </div>
            </div>

            <div className="d-flex flex-wrap">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="Name">Name</label>

                <input
                  required
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="Name"
                  className=""
                  ref={ref}
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="email">Email</label>

                <input
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="email"
                  className=""
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="contact-no">Contact No</label>

                <input
                  required
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  type="number"
                  id="contact-no"
                  className=""
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="father-name">Father's Name</label>

                <input
                  required
                  value={fatherName}
                  onChange={(e) => setFatherName(e.target.value)}
                  type="text"
                  id="father-name"
                  className=""
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="address">Address</label>

                <input
                  required
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  id="address"
                  className=""
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="id-type">Id Type</label>

                <input
                  required
                  value={idType}
                  onChange={(e) => setIdType(e.target.value)}
                  type="text"
                  id="id-type"
                  className=""
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="id-no">Id No</label>
                <input
                  required
                  value={idNo}
                  onChange={(e) => setIdNo(e.target.value)}
                  type="text"
                  id="id-no"
                  className=""
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* CandidateList */}
      <div className="maincontent mt-0">
        <div className="maincontentinner">
          <div
            id="allTicketsTable_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="top"></div>
            <table
              id="allTicketsTable"
              className="table table-bordered display dataTable no-footer"
              style={{ width: "100% " }}
              aria-describedby="allTicketsTable_info"
            >
              <colgroup>
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="allTicketsTable"
                  >
                    #
                  </th>
                  <th
                    className="sorting"
                    tabIndex="1"
                    aria-controls="allTicketsTable"
                  >
                    Name
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="2"
                    aria-controls="allTicketsTable"
                  >
                    Email
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="3"
                    aria-controls="allTicketsTable"
                  >
                    Contact No
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="4"
                    aria-controls="allTicketsTable"
                  >
                    Father's Name
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="5"
                    aria-controls="allTicketsTable"
                  >
                    Address
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="6"
                    aria-controls="allTicketsTable"
                  >
                    IdType
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="7"
                    aria-controls="allTicketsTable"
                  >
                    IdNo
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="8"
                    aria-controls="allTicketsTable"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {availCandidates?.length > 0 ? (
                  <>
                    {availCandidates.map((plan, index) => (
                      <tr className="odd">
                        <td className="ticketModal">
                           {plan?.id}
                        </td>
                        <td className="ticketModal">
                           {plan?.name}
                        </td>
                        <td className="ticketModal">
                           {plan?.email}
                        </td>
                        <td className="ticketModal">
                           {plan?.contactNo}
                        </td>
                        <td className="ticketModal">
                           {plan?.fatherName}
                        </td>
                        <td className="ticketModal">
                           {plan?.address}
                        </td>
                        <td className="ticketModal">
                           {plan?.idType}
                        </td>
                        <td className="ticketModal">
                           {plan?.idNo}
                        </td>

                        <td data-order="New">
                          <div className="ticketDropdown">
                            <a
                              className="f-left status label-info py-1 rounded-pill"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleUpdate(availCandidates, plan.id, e,index)}
                            >
                              <span className="text">Update</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr><td>Nothing to show </td></tr>
                )}
              </tbody>
            </table>
            <div className="bottom">
              <div
                className="dataTables_info"
                id="allTicketsTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 2 of 2 entries
              </div>
              <div className="dataTables_length" id="allTicketsTable_length">
                <label>
                  Show{" "}
                  <select
                    name="allTicketsTable_length"
                    aria-controls="allTicketsTable"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="allTicketsTable_paginate"
              >
                <a
                  href
                  className="paginate_button previous disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="0"
                  tabIndex="-1"
                  id="allTicketsTable_previous"
                >
                  Previous
                </a>
                <span>
                  <a
                    className="paginate_button current"
                    aria-controls="allTicketsTable"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    1
                  </a>
                </span>
                <a
                  href
                  className="paginate_button next disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="2"
                  tabIndex="-1"
                  id="allTicketsTable_next"
                >
                  Next
                </a>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>


    </>
  );
};

export default CandidateManager;
