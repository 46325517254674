import axios from "axios";
import Header from "../../header/Header";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const ResumeSourceManager = () => {
  //state management for input fields
  const [name, setname] = useState("");
  const [place, setPlace] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [email, setEmail] = useState("");
  const [updateFlag,setUpdateFlag] = useState(false)
  const [resumeSourceId,setResumeSourceId] = useState('')
  const ref = useRef(null);
  // add jobrole
  const handleResumeSubmit = async (e) => {
    e.preventDefault();
    if(updateFlag===false){
      const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/resumesource`,
        {
          name,
          place,
          contactNo,
          email,
        }
      );
      window.location.reload();
      setname("");
      setContactNo("");
      setEmail("");
      setPlace("");
    }else{
      const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/resumesource`,
        {
          "id":resumeSourceId,
          name,
          place,
          contactNo,
          email
        }
      );
      setUpdateFlag(false)
      window.location.reload();
    }
  };

  // ResumeSourceList
  const navigate = useNavigate();
  const [availResumes, setAvailResumes] = useState([]);

  // fetch departments
  const getResumes = async () => {
    const { data: resumes } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/resumesource`
    );
    // console.log(resumes);
    setAvailResumes(resumes);
    localStorage.setItem("RESUME_SOURCE",JSON.stringify(resumes))
  };

  const handleUpdate = async(resumeSource, id, e) =>{
    let uname = resumeSource[id-1].name;
    let uplace = resumeSource[id-1].place;
    let ucontact = resumeSource[id-1].contactNo;
    let uemail = resumeSource[id-1].email;
    // console.log(resumeSource);
    setname(uname);
    setPlace(uplace);
    setContactNo(ucontact);
    setEmail(uemail)
    setResumeSourceId(id)
    setUpdateFlag(true)
    if(e.keyCode === 13){
      uname = resumeSource[id-1].name;
      uplace = resumeSource[id-1].place;
      ucontact = resumeSource[id-1].contactNo;
      uemail = resumeSource[id-1].email;
      setname(uname);
      setPlace(uplace);
      setContactNo(ucontact);
      setEmail(uemail)
    }
    ref.current.focus();
  };

  useEffect(() => {
    getResumes();
  }, []);

  return (
    <>
      <Header head="Resume Source Manager" icon="fa-sharp fa-solid fa-file" />

      <div className="maincontent">
        <div className="maincontentinner">
          <form
            className="formModal"
            method="post"
            style={{ minWidth: "250px" }}
            onSubmit={handleResumeSubmit}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa-solid fa-square-plus"></i>  Resume{" "}
                </h4>
                <input type="submit" value={`${updateFlag ? 'Update' : 'Add'}`} className="btn btn-primary" />
              </div>
            </div>

            <div className="d-flex flex-wrap ">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="">Source Name</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  className=""
                  id=""
                  ref={ref}
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="">Place </label>
                <input
                  type="text"
                  value={place}
                  onChange={(e) => setPlace(e.target.value)}
                  className=""
                  id=""
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="">Contact No</label>
                <input
                  type="number"
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  className=""
                  id=""
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="">Email</label>

                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  className=""
                  id=""
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* ResumesourceList */}
      <div className="maincontent mt-0">
        <div className="maincontentinner">
          <div
            id="allTicketsTable_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="top"></div>
            <table
              id="allTicketsTable"
              className="table table-bordered display dataTable no-footer"
              style={{ width: "100% " }}
              aria-describedby="allTicketsTable_info"
            >
              <colgroup>
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="allTicketsTable"
                  >
                    #
                  </th>
                  <th
                    className="sorting"
                    tabIndex="1"
                    aria-controls="allTicketsTable"
                  >
                    Name
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="2"
                    aria-controls="allTicketsTable"
                  >
                    Email
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="3"
                    aria-controls="allTicketsTable"
                  >
                    Contact No
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="4"
                    aria-controls="allTicketsTable"
                  >
                    Place
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="5"
                    aria-controls="allTicketsTable"
                  >
                    Created At
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="6"
                    aria-controls="allTicketsTable"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {availResumes?.length > 0 ? (
                  <>
                    {availResumes.map((plan, index) => (
                      <tr className="odd">
                        <td className="ticketModal">
                            {plan.id}
                        </td>
                        <td
                         className="ticketModal"> {plan.name}
                        </td>
                        <td
                          className="ticketModal"> {plan.contactNo}
                        </td>
                        <td
                          className="ticketModal"> {plan.email}
                        </td>
                        <td
                          className="ticketModal"> {plan.place}
                        </td>
                        <td
                         className="ticketModal"> {moment(plan.created_at).utc().format('ll @ LT')}
                        </td>

                        <td data-order="New">
                          <div className="ticketDropdown">
                            <a
                              className="f-left status label-info py-1 rounded-pill"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleUpdate(availResumes, plan.id, e)}
                            >
                              <span className="text">Update</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr><td>Nothing to show </td></tr>
                )}
              </tbody>
            </table>
            <div className="bottom">
              <div
                className="dataTables_info"
                id="allTicketsTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 2 of 2 entries
              </div>
              <div className="dataTables_length" id="allTicketsTable_length">
                <label>
                  Show{" "}
                  <select
                    name="allTicketsTable_length"
                    aria-controls="allTicketsTable"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="allTicketsTable_paginate"
              >
                <a
                  href
                  className="paginate_button previous disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="0"
                  tabIndex="-1"
                  id="allTicketsTable_previous"
                >
                  Previous
                </a>
                <span>
                  <a
                    className="paginate_button current"
                    aria-controls="allTicketsTable"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    1
                  </a>
                </span>
                <a
                  href
                  className="paginate_button next disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="2"
                  tabIndex="-1"
                  id="allTicketsTable_next"
                >
                  Next
                </a>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResumeSourceManager;
