import axios from "axios";
import Header from "../../header/Header";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const DepartmentManager = () => {
  const [name, setname] = useState("");
  const [updateFlag,setUpdateFlag] = useState(false)
  const [deptId,setDeptId] = useState('')
  // const [updatedName, setUpdatedName] = useState('');
  const ref = useRef(null);

  // add dept
  const handleDepSubmit = async (e) => {
    e.preventDefault();
    if(updateFlag===false){
      const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/departments`,
      {
        name,
      }
    );
    window.location.reload();
    setname("");
    }else{
      const inExeHit = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/departments`,
        {
          "id":deptId,
          name
        }
      );
      setUpdateFlag(false)
      window.location.reload();
    }
  };

  // DepartmentList
  const navigate = useNavigate();
  const [availDepartments, setAvailDepartments] = useState([]);

  // fetch departments
  const getDepartments = async () => {
    const { data: departments } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/departments`
    );
    setAvailDepartments(departments);
    localStorage.setItem("ALL_DEPARTMENT",JSON.stringify(departments))
  };

  const handleUpdate = async(departments, id, e) =>{
    // console.log(departments[id-1]);
    // console.log(departments[id-1].name);
    // setUpdatedName(uname);
    let uname = departments[id-1].name;
    setname(uname);
    setDeptId(id)
    setUpdateFlag(true)
    if(e.keyCode === 13){
      uname = departments[id-1].name;
      setname(uname);
    }
    // console.log(uname,id,departments);
    ref.current.focus();
  };

  useEffect(() => {
    getDepartments();
  }, []);

  // console.log(name);

  return (
    <>
      <Header head="Department Manager" icon="fa-sharp fa-solid fa-building" />
      <div className="maincontent">
        <div className="maincontentinner">
          <form
            className="formModal"
            method="post"
            style={{ minWidth: "250px" }}
            onSubmit={handleDepSubmit}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa-solid fa-square-plus"></i> Department{" "}
                </h4>
                <input type="submit" value={`${updateFlag ? 'Update' : 'Add'}`} className="btn btn-primary" />
              </div>
            </div>

            <div className="">
              <div>
                <label htmlFor="department-name">Department Name</label>
                {
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setname(e.target.value)}
                    className=""
                    id="department-name"
                    ref={ref}
                  />
                }
              </div>
            </div>  
          </form>
        </div>
      </div>

      {/* departmentList */}
      <div className="maincontent mt-0">
        <div className="maincontentinner">
          <div
            id="allTicketsTable_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="top"></div>
            <table
              id="allTicketsTable"
              className="table table-bordered display dataTable no-footer"
              style={{ width: "100% " }}
              aria-describedby="allTicketsTable_info"
            >
              <colgroup>
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="allTicketsTable"
                  >
                    #
                  </th>
                  <th
                    className="sorting"
                    tabIndex="1"
                    aria-controls="allTicketsTable"
                  >
                    Department
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="2"
                    aria-controls="allTicketsTable"
                  >
                    Created At
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="3"
                    aria-controls="allTicketsTable"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {availDepartments?.length > 0 ? (
                  <>
                    {availDepartments.map((plan, index) => (
                      <tr className="odd" key={plan.id}>
                        <td
                          className="ticketModal"> {plan.id}
                        </td>
                        <td
                           className="ticketModal"> {plan.name}
                        </td>
                        <td
                           className="ticketModal"> {moment(plan.created_at).utc().format('ll @ LT')}
                        </td>

                        <td data-order="New">
                          <div className="ticketDropdown">
                            <a
                              className="f-left status label-info py-1 rounded-pill"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleUpdate(availDepartments, plan.id, e)}
                            >
                              <span className="text">Update</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr><td>No departments to show</td></tr>
                )}
              </tbody>
            </table>
            <div className="bottom">
              <div
                className="dataTables_info"
                id="allTicketsTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 2 of 2 entries
              </div>
              <div className="dataTables_length" id="allTicketsTable_length">
                <label>
                  Show{" "}
                  <select
                    name="allTicketsTable_length"
                    aria-controls="allTicketsTable"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="allTicketsTable_paginate"
              >
                <a
                  href
                  className="paginate_button previous disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="0"
                  tabIndex="-1"
                  id="allTicketsTable_previous"
                >
                  Previous
                </a>
                <span>
                  <a
                    className="paginate_button current"
                    aria-controls="allTicketsTable"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    1
                  </a>
                </span>
                <a
                  href
                  className="paginate_button next disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="2"
                  tabIndex="-1"
                  id="allTicketsTable_next"
                >
                  Next
                </a>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DepartmentManager;
