import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const InterviewPlanList = () => {

    // navigator
    const navigate = useNavigate();

    // states for plans 
    const [availInterplans, setAvailInterPlans] = useState([]);

    const getInterviewPlans = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/interviewplans`);
        // console.log(data);
        setAvailInterPlans(data);
    }

    useEffect(() => {
        getInterviewPlans();
    }, []);

    return (
        <>
            <div className='maincontent mt-0'>
                <div className="maincontentinner">
                    <div id="allTicketsTable_wrapper" className="dataTables_wrapper no-footer"><div className="top"></div>
                        <table id="allTicketsTable" className="table table-bordered display dataTable no-footer" style={{
                            width: "100% "
                        }} aria-describedby="allTicketsTable_info">
                            <colgroup>
                                <col className="con1" />
                                <col className="con0" />
                                <col className="con1" />
                                <col className="con0" />
                                <col className="con1" />
                                <col className="con0" />
                                <col className="con1" />
                                <col className="con0" />
                                <col className="con1" />
                                <col className="con0" />
                                <col className="con1" />
                                <col className="con0" />
                            </colgroup>
                            <thead>
                                <tr>
                                    <th className="sorting" tabIndex="0" aria-controls="allTicketsTable" >
                                        #
                                    </th>
                                    <th className="sorting" tabIndex="1" aria-controls="allTicketsTable" >
                                        Department
                                    </th>
                                    <th className="status-col sorting" tabIndex="2" aria-controls="allTicketsTable" >
                                        Jobrole
                                    </th>
                                    <th className="status-col sorting" tabIndex="3" aria-controls="allTicketsTable" >
                                        Pay-Role Type
                                    </th>
                                    <th className="status-col sorting" tabIndex="4" aria-controls="allTicketsTable" >
                                        Resume Source
                                    </th>
                                    <th className="status-col sorting" tabIndex="5" aria-controls="allTicketsTable" >
                                        Interview Mode
                                    </th>
                                    <th className="status-col sorting" tabIndex="6" aria-controls="allTicketsTable" >
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody >
                                {
                                    availInterplans.length > 0 ?
                                        <>
                                            {
                                                availInterplans.map((plan, index) =>

                                                    <tr className="odd" >
                                                        <td>
                                                            <a href className="ticketModal"> {plan.id}</a>
                                                        </td>
                                                        <td>
                                                            <a href className="ticketModal"> {plan.departmentId}</a>
                                                        </td>
                                                        <td>
                                                            <a href className="ticketModal"> {plan.jobRoleId}</a>
                                                        </td>
                                                        <td>
                                                            <a href className="ticketModal"> {plan.jobPayRoleType}</a>
                                                        </td>
                                                        <td>
                                                            <a href className="ticketModal"> {plan.resumeCollectionId}</a>
                                                        </td>
                                                        <td>
                                                            <a href className="ticketModal"> {plan.interMode}</a>
                                                        </td>

                                                        <td data-order="New">
                                                            <div className='ticketDropdown'>
                                                                <a href className="f-left status label-info py-1 rounded-pill"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => navigate(`/interviewplans/${plan?.id}`)}>
                                                                    <span className="text">
                                                                        Start Interview
                                                                    </span>
                                                                </a>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                        </>
                                        :
                                        <tr><td>No interview plans to show</td></tr>
                                }
                            </tbody>

                        </table>
                        <div className="bottom"><div className="dataTables_info" id="allTicketsTable_info" role="status" aria-live="polite">Showing 1 to 2 of 2 entries</div>
                            <div className="dataTables_length" id="allTicketsTable_length">
                                <label>Show
                                    <select name="allTicketsTable_length" aria-controls="allTicketsTable" className="">
                                        <option value="10">10</option>
                                        <option value="25">25</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select> entries
                                </label>
                            </div>
                            <div className="dataTables_paginate paging_simple_numbers" id="allTicketsTable_paginate">
                                <a href className="paginate_button previous disabled" aria-controls="allTicketsTable" data-dt-idx="0" tabIndex="-1" id="allTicketsTable_previous">Previous
                                </a>
                                <span>
                                    <a href className="paginate_button current" aria-controls="allTicketsTable" data-dt-idx="1" tabIndex="0">1
                                    </a>
                                </span>
                                <a href className="paginate_button next disabled" aria-controls="allTicketsTable" data-dt-idx="2" tabIndex="-1" id="allTicketsTable_next">Next
                                </a>
                            </div>
                        </div>
                        <div className="clear"></div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default InterviewPlanList;

