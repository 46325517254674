import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../header/Header";
import moment from "moment";
import axios from "axios";
import parse from 'html-react-parser';

const CandidateList = () => {
  const { interPlanId } = useParams();

  const navigate = useNavigate();
  // comp lvl state

  const updateTotalAppCount = async (e) => {
    e.preventDefault();
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewplans`,
      {
        "id":interPlanId,
       "totalApplication": totalApplicationCount
      }
      );
    };
    
    // "totalApplication": totalApplicationCount
  const [availCandidates, setAvailCandidates] = useState([]);
  const [applicationFunalData, setApplicationFunalData] = useState([]);
  const [totalApplicationCount, setTotalApplicationCount] = useState(0);

  const getCandidates = async () => {
    const { data: candidates } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewplans/${interPlanId}/candidateList`
    );
    setAvailCandidates(candidates.interviewExicutionData);
    setApplicationFunalData(candidates.interviewFunnel);
    setTotalApplicationCount(candidates.interviewFunnel.applicationCount);
  };

  useEffect(() => {
    getCandidates();
  }, []);
  return (
    <>
      <Header head="Candidate List In Interview" icon="fa-solid fa-users"></Header>
      <div className="maincontent">
        <div className="maincontentinner">
          <div
            id="allTicketsTable_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="top">
              <div className="row">
                <div className="col-sm-2">
                  <span
                    className="status label-info py-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/interviewplans/${interPlanId}`)}
                  >Intreview Room</span>
                  &nbsp;|&nbsp;
                  <b>Total Applications: </b>
                  <input
                    required
                    value={totalApplicationCount}
                    onChange={(e) => setTotalApplicationCount(e.target.value)}
                    type="text"
                    id="Name"
                    className=""
                  />
                <input type="submit" onClick={updateTotalAppCount} className="btn btn-primary" />
                &nbsp;|&nbsp;
                <b>Candidate count: </b>{applicationFunalData.candidateCount}
                &nbsp;|&nbsp;
                <b>Round 1: </b>{applicationFunalData.intRoundComp1}
                &nbsp;|&nbsp;
                <b>Round 2: </b>{applicationFunalData.intRoundComp2}
                &nbsp;|&nbsp;
                <b>Round 3: </b>{applicationFunalData.intRoundComp3}
                </div>
              </div>
            </div>
            <table
              id="allTicketsTable"
              className="table table-bordered display dataTable no-footer"
              style={{ width: "100% " }}
              aria-describedby="allTicketsTable_info"
            >
              <colgroup>
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
              </colgroup>
              <thead>
                <tr>
                  {/* <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="allTicketsTable"
                  >
                    #
                  </th> */}
                  <th
                    className="sorting"
                    tabIndex="1"
                    aria-controls="allTicketsTable"
                  >
                    Name
                  </th>
                  {/* <th
                    className="status-col sorting"
                    tabIndex="2"
                    aria-controls="allTicketsTable"
                  >
                    Round
                  </th> */}
                  <th
                    className="status-col sorting"
                    tabIndex="3"
                    aria-controls="allTicketsTable"
                  >
                    Last Disc.
                  </th>
                  {/* <th
                    className="status-col sorting"
                    tabIndex="4"
                    aria-controls="allTicketsTable"
                  >
                    Status
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="5"
                    aria-controls="allTicketsTable"
                  >
                    Life-Cycle
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="6"
                    aria-controls="allTicketsTable"
                  >
                    Performance
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="7"
                    aria-controls="allTicketsTable"
                  >
                    Created
                  </th> */}
                  <th
                    className="status-col sorting"
                    tabIndex="8"
                    aria-controls="allTicketsTable"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {availCandidates?.length > 0 ? (
                  <>
                    {availCandidates.map((plan, index) => (
                    <tr className={`odd starPerformar${plan?.starPerformer}`}>
                        {/* <td className="ticketModal"> {plan?.id}</td> */}
                        <td className="ticketModal">
                          <p style={{color:'blue', cursor: "pointer"}} onClick={() => navigate(`/interviewplans/${plan?.interPlanId}/${plan?.contactNo}`)}>{plan?.canName}</p>
                          <p>Round: {plan?.intRoundComlted == null ? 0 : plan?.intRoundComlted}</p>
                          <p className={`intStatus_${plan?.interCandStatus}`}>{plan?.interCandStatus}</p>
                          {plan?.finalConDate} {plan?.finalConDate && <br/>}
                          {!!plan?.finalCon ? parse(plan?.finalCon) : <p></p>}
                          <p>{plan?.acceptanceLevel === null ? 0 : plan?.acceptanceLevel} / {plan?.starPerformer === 1 ? 'Star' : 'Non-Star'}</p>
                        </td>
                        {/* <td className="ticketModal"> </td> */}
                        <td className={"ticketModal"}> 
                          <p className={`empty_box${plan?.last_discution[0].activityStatus}`}>
                          <span className={`mse_inf_${plan?.last_discution[0].activityStatus}`}>{plan?.last_discution[0].activityType} :</span> {moment(plan?.last_discution[0].effectiveTime) .utc() .format("ll @ LT")}
                          </p>
                          <p>
                          {parse(plan?.last_discution[0].intExeDiscution)}
                          </p>
                        </td>
                        {/* <td className="ticketModal">
                        </td>
                        <td className="ticketModal"> {plan?.interLifeCycleStatus}
                        </td>
                        <td className={`ticketModal starPerformar${plan?.starPerformer}`}> 
                        </td>
                        <td className="ticketModal"> {moment(plan?.created_at) .utc() .format("ll @ LT")}
                        </td> */}
                        <td data-order="New">
                          <div className="ticketDropdown">
                            <a
                              className="f-left status label-info py-1 rounded-pill"
                              style={{ cursor: "pointer" }}
                              onClick={() => navigate(`/interviewplans/${plan?.interPlanId}/${plan?.contactNo}`)}
                            >
                              <span className="text">Interview Room</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr><td>Nothing to show </td></tr>
                )}
              </tbody>
            </table>
            <div className="bottom">
              <div
                className="dataTables_info"
                id="allTicketsTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 2 of 2 entries
              </div>
              <div className="dataTables_length" id="allTicketsTable_length">
                <label>
                  Show{" "}
                  <select
                    name="allTicketsTable_length"
                    aria-controls="allTicketsTable"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="allTicketsTable_paginate"
              >
                <a
                  href
                  className="paginate_button previous disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="0"
                  tabIndex="-1"
                  id="allTicketsTable_previous"
                >
                  Previous
                </a>
                <span>
                  <a
                    className="paginate_button current"
                    aria-controls="allTicketsTable"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    1
                  </a>
                </span>
                <a
                  href
                  className="paginate_button next disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="2"
                  tabIndex="-1"
                  id="allTicketsTable_next"
                >
                  Next
                </a>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </>
  )
}
export default CandidateList;