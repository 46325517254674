import axios from 'axios';
import React, { useEffect, useState } from 'react';


const BasicScreeningList = () => {
    // const navigate=useNavigate();
    //   const handleClick=()=>{
    //     navigate("/basicScreeningManager")
    //   }

    // state for the basic screening qs
    const [availBasicScreening, setAvailBasicScreening] = useState([]);

    const getBasicScreenings = async () => {
        const { data } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/basicscreening`);
        setAvailBasicScreening(data);
    }

    // mount
    useEffect(() => {
        getBasicScreenings();
    }, []);

    return (
        <div>


            <div className="container basicscreening-list-container">
                <div className="basicscreening-list-ul">
                    {
                        availBasicScreening.length > 0 ?
                            <>
                                {
                                    availBasicScreening.map((basic, index) =>
                                        <>
                                            <li key={index}>
                                                <p>Question id : {basic.id}</p>
                                                <p>Question : {basic.question}</p>
                                                <p>Qualifying ans : {basic.qualifyingAns}</p>
                                                <p>Question description : {basic.quesDescription}</p>
                                            </li>
                                        </>
                                    )
                                }
                            </>
                            :
                            <>
                                <p>No basic screening questions to show</p>
                            </>
                    }


                </div>
            </div>
        </div>
    )
}

export default BasicScreeningList;
