import axios from "axios";
import $ from "jquery";
import moment from "moment";

export const fetchHistory = async (interPlanId, candSearchResId, search) => {
  const { data: oldInterviews } = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/interviewsGiven/${candSearchResId}`
  );
  // console.log("int pland",interPlanId);
  // console.log("int olpand",oldInterviews[0].interPlanId);
  if (oldInterviews[0] != undefined && oldInterviews[0]?.id != interPlanId) {
    oldInterviews.forEach((oldInterview) => {
      displayInHistorySection(
        oldInterview,
        "historyTable",
        oldInterview.interPlanId,
        interPlanId,
        search
      );
    });
  } else {
    $("#oldApplications").html("<b>No Old Interview found.</b>");
  }
};

export function displayInHistorySection(
  _data,
  caseName,
  oldApp = "",
  interPlanId,
  search
) {
  let data ={}
  //data sanitisation : if data is undefined it chamges 
  for (var key of Object.keys(_data)) {
    data[key]=_data[key]
    if(!_data[key]){
      data[key]=""
    }
  }

  let dateValue = "";
  if(data.activityType){
    // alert(data.activityType)
    dateValue = 
    "<span class='mse_inf_" +
      data.activityStatus +
      "'>" +
      data.activityType +
      "</span>"
      if(data.effectiveTime){
        dateValue += " : "+moment(data.effectiveTime).format("ll @ LT::")
      }
  }
  // console.log("some data", data, oldApp, interPlanId, search);
  const obj = {
    historyFromApi:
      "<b>" +
      moment(data.created_at).utc().format("ll @ LT") +
      "</b><br/>" +
      dateValue +
      data.intExeDiscution +
      "<br />",
      finalConclusion:
      "Candidate <b>" +
      data[0]?.interCandStatus +
      "</b>" +
      " because <b>" +
      data[0]?.finalCon +
      "</b> <p class='text-primary me-1 fs-8 btn-link' id='intReopen' style='cursor:pointer'>" +
      "reopen" +
      "</p> </br>",
    accCreated: "<b>"+data[0] + "</b> : Profile Created",
    case4: "Application Accepted: " + data,
    historyTable:
      "<td className='border border-1'>" +
      moment(data.created_at).utc().format("ll @ LT") +
      "</td><td className='border border-1'> " +
      data.interPlanId +
      " </td><td className='border border-1'> " +
      data.finalCon +
      " </td><td className='border border-1'> " +
      data.finalConDate +
      "</td>",
  };

  // console.log('case6', obj[`${caseName}`],interPlanId,oldApp)
  if (oldApp != "") {
    if (oldApp != interPlanId) {
      // console.log('ca6', obj[`${caseName}`])
      $("#oldApplications").html(
        "<tr className='border border-1'>" +
        obj[`${caseName}`] +
          "<td><a href='/interviewplans/" +
          oldApp +
          "/" +
          search +
          "' target='_block' rel='noreferrer'>View</a> </td></tr>" +
          $("#oldApplications").html()
      );
    }
  } else {
    // console.log(data);
    $("#historySection").html(
      // "<li>" + message + "</li>" + $("#historySection").html()
      "<li>" +obj[`${caseName}`] + "</li>" + $("#historySection").html()
    );
  }
  
  // console.log("obj case", obj[`${caseName}`]);
}
