import React from "react";
const Header = (props) => {
    return (
        <>
            <div className="rightpanel" style={{ position: "relative", marginLeft: "0px" }}>
                <div className="pageheader">
                    <div className="pageicon"><i className={props.icon}></i></div>
                    <div className="pagetitle">
                        <h1>{props.head}</h1>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Header;