import axios from "axios";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Header from "../../header/Header";
import $ from "jquery";
import moment from "moment";
import SearchBarSuggestion from "../SearchBarSuggestion";
import { displayInHistorySection } from "../ShowHistory";

const SearchCandidate = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [address, setAddress] = useState("");
  const [idType, setIdType] = useState("");
  const [idNo, setIdNo] = useState("");

  // submit the form
  const handleCandidateUpdate = async (e) => {
    e.preventDefault();
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`,
      {
        name,
        email,
        contactNo,
        fatherName,
        address,
        idType,
        idNo,
      }
    );
    if (!res) {
      console.log("Error");
    }
    console.log("Success");
  };
  //param for url routing
  const {candidateSearchWith } = useParams();

  const [search, setSearchFiled] = useState(candidateSearchWith);

  const [interPlanId, setInterPlanId] = useState("");

  const [candidateId, setCanIdNo] = useState("");

  const [oldInterDoneList, setOldInterviewDone] = useState([]);

  const loadinputToDefault = () => {
    // alert("reset")
    setCanIdNo("");

    $("#maincontentinner").hide();
    $("#candiateEditForm").hide();
    $("#finalConclusion").hide();
    $("#interviewDiscutionForm").hide();
    $("#historyContainerInner").hide();
    $("#firstIntraction").html("");
    $("#oldApplications").html("");
    $("#historySection").html("");
  };

  const fetchInterviewDiscussion = async (data_intPlanId) => {
    const { data: thisIntDiscutions } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/${data_intPlanId}/getDiscution/${candidateId}`
    );
    console.log("history sction data",thisIntDiscutions);
    $("#historySection").html("");
    if (thisIntDiscutions[0] != undefined) {
      thisIntDiscutions.forEach((thisIntDiscution) => {
        displayInHistorySection(thisIntDiscution,"historyFromApi")
      });
    }
  }

  const fetchCandidate = async (e) => {
    e.preventDefault();

    loadinputToDefault();
    const { data: candSearchRes } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile/search/${search}`
    );
    if (candSearchRes[0]?.id == undefined) {
      //Candidate Not found
      alert("No Record Found")
      setContactNo(search);
      //Fresh interview
    } else {
      //Candidate history found
      $("#candiateEditForm").hide();
      setCanIdNo(candSearchRes[0]?.id);
      setName(candSearchRes[0]?.name);
      setEmail(candSearchRes[0]?.email);
      setContactNo(candSearchRes[0]?.contactNo);
      setFatherName(candSearchRes[0]?.fatherName);
      setAddress(candSearchRes[0]?.address);
      setIdType(candSearchRes[0]?.idType);
      setIdNo(candSearchRes[0]?.idNo);

      //Setup history section
      $("#maincontentinner").show();
      $("#historyContainerInner").show();

      $("#firstIntraction").html(
        "<li style='margin-left:0'><b>"+candSearchRes[0]?.name+" </b><br />" +
        moment(candSearchRes[0]?.created_at)
          .utc()
          .format("ll @ LT") +
        "<br />"+candSearchRes[0]?.address+"</li>"
      );

      //Find Interview appeared in
      const { data: oldInterviews } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/interviewsGiven/${candSearchRes[0]?.id}`
      );
      if ( oldInterviews[0] != undefined ) {
        setOldInterviewDone(oldInterviews)
      } else {
        $("#oldApplications").html("<b>No Old Interview found.</b>");
      }
    }
  };

  const [availInterplans, setAvailInterPlans] = useState([]);
  const [searchSuggestion, setSearchSuggestion] = useState([])

  const getInterviewPlans = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewplans`
    );
    setAvailInterPlans(data);
    console.log("data intplan",availInterplans);
  };

  useEffect(() => {
    getInterviewPlans();
  }, []);

  return (
    <>
      <Header head="Candidate Details" icon="fa-solid fa-people-arrows" />
      <div className="maincontent d-flex justify-content-evenly align-items-start flex-wrap">
        <div className="col-md-6">
          <div className="maincontentinner">
            <form className="fetch-form container" onSubmit={fetchCandidate}>
              <label>Fetch Candidate</label>
              <div>
                <input
                  value={search}
                  autoComplete={"off"}
                  autoFocus
                  onChange={(e) => setSearchFiled(e.target.value)}
                  type="text"
                  placeholder="Search the candidate"
                  className="w-100"
                />
                {
                  search &&
                  <SearchBarSuggestion txtInput={search} data={availInterplans}/>
                  }
                <div className="d-flex justify-content-between align-items-center">
                <button
                  type="submit"
                  id="btnSearch"
                  className="btn btn-primary"
                >
                  Search
                </button>
                {oldInterDoneList?.length > 0 && <label  className="text-primary me-1 fs-6" onClick={()=>window.open("tel:" + contactNo, "_block","popup")}>
                  Call
                </label>}
                </div>
              </div>
            </form>
          </div>
          <div className="maincontentinner">
            <ul
                  id="firstIntraction"
                  className="mb-1 ps-0 m-0"
                  style={{ listStyle: "none" }}
                ></ul>
                {
                      oldInterDoneList?.length > 0 ?
                      <>   
                <hr
                  style={{
                    border: "2px dotted rgb(181, 171, 171)",
                    marginTop: " 5px",
                  }}
                />
                <b>Interview History::</b>
                <table className="border border-1 w-100 mt-2">
                  <thead className="border border-1 ">
                    <tr className="border border-1 text-center">
                      <th className="border border-1">Date&Time</th>
                      <th className="border border-1">Int. Plan</th>
                      <th className="border border-1">Status</th>
                      <th className="border border-1">Final Conclusion</th>
                      <th className="border border-1">FC Date</th>
                      <th className="border border-1">Interview</th>
                    </tr>
                  </thead>
                  <tbody
                    id="oldApplicationsa"
                    className="mb-1 border border-1 text-center"
                  >
                      {oldInterDoneList.map((oldInterDone, index) =>
                        <tr>
                          <td className='border border-1'>
                            <button className="btn-link" onClick={() => fetchInterviewDiscussion(oldInterDone?.interPlanId)}>
                              {moment(oldInterDone?.created_at).utc().format("ll")}
                              {/* <br />
                              {moment(oldInterDone?.created_at).utc().format("HH:mm A")} */}
                            </button>
                          </td>
                          <td className='border border-1 btn-link' onClick={()=>navigate(`/interviewplans/${oldInterDone?.interPlanId}/${search}`)}> {oldInterDone?.interPlanId} </td>
                          <td className='border border-1'> {oldInterDone?.interCandStatus} </td>
                          <td className='border border-1'> {oldInterDone?.finalCon} </td>
                          <td className='border border-1'> {oldInterDone?.finalConDate} </td>
                          <td className='border border-1'> {oldInterDone?.interLifeCycleStatus} </td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                    <hr
                      style={{
                        border: "2px dotted rgb(181, 171, 171)",
                        marginTop: " 5px",
                      }}
                    />
                    </>
                    :
                    <p>Waitting for data</p>
                  }
          </div>
        
        <div className="maincontentinner" id="candiateEditForm" style={{ display: "none" }}>
          <form
            className="formModal"
            style={{ minWidth: "250px" }}
            onSubmit={handleCandidateUpdate}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa-solid fa-square-plus"></i> Candidate{" "}
                </h4>
                <input type="submit" value="Add" className="btn btn-primary" />
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="Name">Name</label>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                  id="Name"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="email">Email</label>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  id="email"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="contact-no">Contact No</label>
                <input
                  value={contactNo}
                  onChange={(e) => setContactNo(e.target.value)}
                  type="number"
                  id="contact-no"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="father-name">Father's Name</label>
                <input
                  value={fatherName}
                  onChange={(e) => setFatherName(e.target.value)}
                  type="text"
                  id="father-name"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="address">Address</label>
                <input
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                  type="text"
                  id="address"
                />
              </div>

              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="id-type">Id Type</label>
                <input
                  value={idType}
                  onChange={(e) => setIdType(e.target.value)}
                  type="text"
                  id="id-type"
                />
              </div>
              <div className="col-lg-3 col-md-4 col-sm-6">
                <label htmlFor="id-no">Id No</label>
                <input
                  value={idNo}
                  onChange={(e) => setIdNo(e.target.value)}
                  type="text"
                  id="id-no"
                />
              </div>
            </div>
          </form>
        </div>
      </div>

        <div className="maincontentinner col-md-6" id="historyContainer">
          <section>
            <div className="col-md-12 d-flex justify-content-between align-items-start">
              <h4 className="widgettitle title-light">
                <i className="fa-sharp fa-solid fa-clock-rotate-left"></i> History{" "}
              </h4>
            </div>
            {
              <div className="col-12 ms-4" id="historyContainerInner" style={{ display: "none" }}>
                <b>Interview Discussions::</b>
                <ol id="historySection" className="mb-1 ms-3 mt-2"></ol>
              </div>
            }
          </section>
        </div>
      </div>
    </>
  );
};
export default SearchCandidate;
