import './App.css';
import { BrowserRouter as BRouter, Routes, Route } from 'react-router-dom';
import DepartmentList from './components/inview/departments/DepartmentList';
import DepartmentManger from './components/inview/departments/DepartmentManager';
import Home from './components/outview/home/Home';
import JobRoleList from './components/inview/jobroles/JobRoleList';
import JobRoleManager from './components/inview/jobroles/JobRoleManager';
import CandidateList from './components/inview/candidates/CandidateList';
import CreateInterviewPlan from './components/inview/interviewplan/CreateInterviewPlan';
import ResumeSourceList from './components/inview/resumesources/ResumeSourceList';
import ResumeSourceManager from './components/inview/resumesources/ResumeSourceManager';
import InterviewPlanList from './components/inview/interviewplan/InterviewPlanList';
import CandidateManager from './components/inview/candidates/CandidateManager';
import FetchCandidate from './components/inview/fetchcandidate/FetchCandidate';
import BasicScreeningList from './components/inview/basicscreenings/BasicScreeningList';
import BasicScreeningManager from './components/inview/basicscreenings/BasicScreeningManager';
import CandidatesInInterview from './components/inview/fetchcandidate/CandidatesInInterview';
import Navbar from "./components/navbar/Navbar"
import SearchCandidate from './components/inview/candidates/SearchCandidate';
function App() {
  return (
    <BRouter>
      <div className='App'>
        < Navbar />
        <Routes>
          <Route path="/" element={<> <Home /> </>}></Route>
          <Route path="/departmentList" element={<> <DepartmentList /> </>}></Route>
          <Route path="/departmentManager" element={<> <DepartmentManger /> </>}></Route>
          <Route path="/jobroleList" element={<> <JobRoleList /> </>}></Route>
          <Route path="/jobroleManager" element={<> <JobRoleManager /> </>}></Route>
          <Route path="/resumesourceList" element={<> <ResumeSourceList /> </>}></Route>
          <Route path="/resumesourceManager" element={<> <ResumeSourceManager /> </>}></Route>
          <Route path="/candidatelist" element={<> <CandidateList /> </>}></Route>
          <Route path="/candidatelist/:interPlanId" element={<> <CandidatesInInterview /> </>}></Route>
          <Route path="/candidateManager" element={<> <CandidateManager /> </>}></Route>
          <Route path="/basicscreeningList" element={<> <BasicScreeningList /> </>}></Route>
          <Route path="/basicScreeningManager" element={<> <BasicScreeningManager /> </>}></Route>
          <Route path="/viewinterplans" element={<> <InterviewPlanList /> </>}></Route>
          <Route path="/createinterplan" element={<> <CreateInterviewPlan /> </>}></Route>
          <Route path="/interviewplans/:interPlanId" element={<> <FetchCandidate /> </>}></Route>
          <Route path="/interviewplans/:interPlanId/:candidateSearchWith" element={<> <FetchCandidate /> </>}></Route>
          <Route path="/searchCandidate/" element={<> <SearchCandidate /> </>}></Route>
          <Route path="/searchCandidate/:candidateSearchWith" element={<> <SearchCandidate /> </>}></Route>
        </Routes>
      </div>
    </BRouter>
  );
}

export default App;