import React from "react";
import "./navbar.css"
import { NavLink, Link } from "react-router-dom";
import Logo from "../../images/logo1.png"
const Navbar = () => {
  const hideLeftpanel = () => {
    const leftMenu = document.getElementById("offcanvasDarkNavbar")
    leftMenu.setAttribute("data-bs-dismiss",
      "offcanvas"
    );
  }
  return (
    <>
      <div className="header" style={{ marginLeft: "0px", width: "100%" }}>
        <div className="headerinner" style={{ marginLeft: "0px" }}>
          <a href className="navbar-toggler border-0 navbar-icon-btn barmenu-icon" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" style={{ outline: "none", border: "none", color: "white" }} onClick={hideLeftpanel}>
            <i className=""></i>
            <span className="fa-sharp fa-solid fa-bars fw-bold"></span>
          </a>
      
          <ul className="headmenu" style={{position: "fixed",
    left: "1%",
    top:"-1px",
    marginLeft:"55px"
}}>
          <li>
              <Link to="/">
                <span className="head-icon fa fa-home"></span><span className="headmenu-label">Home</span> </Link>
            </li>
          </ul>
      
          <div className="offcanvas offcanvas-start" tabIndex="-1" id="offcanvasDarkNavbar" 
          
          aria-labelledby="offcanvasDarkNavbarLabel" style={{ width: "260px", backgroundColor: "white" }}>
       
            <div className="offcanvas-header border border-bottom-secondary">
              <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel"><Link to="/"><img alt='SymsTech-logo' src={Logo}  style={{ width: "200px" }} /></Link></h5>
              <button type="button" className="btn-close btn-close-dark" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            </div>
            <div className="offcanvas-body px-0">
              <ul className="navbar-nav justify-content-end flex-grow-1">          
          
                <li className="navbar-item p-0">
                  <NavLink className=" text-decoration-none d-block" style={{ color: "#555555", padding: "10px", fontFamily: "var(--primary-font-family)", fontWeight: "300", fontSize: "14px" }} aria-current="page" to="/createinterplan" onClick={hideLeftpanel}>New Interview Plan</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="  text-decoration-none d-block" style={{ color: "#555555", padding: "10px", fontFamily: " var(--primary-font-family)", fontWeight: "300", fontSize: "14px" }} aria-current="page" to="/departmentManager" onClick={hideLeftpanel}>Department Manager</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="  text-decoration-none d-block" style={{ color: "#555555", padding: "10px", fontFamily: "var(--primary-font-family)", fontWeight: "300", fontSize: "14px" }} aria-current="page" to="/jobroleManager" onClick={hideLeftpanel}>Job Role Manager</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="  text-decoration-none d-block" style={{ color: "#555555", padding: "10px", fontFamily: "var(--primary-font-family)", fontWeight: "300", fontSize: "14px" }} aria-current="page" to="/resumesourceManager" onClick={hideLeftpanel}>Resume Source Manager</NavLink>
                </li>
                <li className="navbar-item">
                  <NavLink className="text-decoration-none d-block" style={{ color: "#555555", padding: "10px", fontFamily: "var(--primary-font-family)", fontWeight: "300", fontSize: "14px" }} aria-current="page" to="/candidateManager" onClick={hideLeftpanel}>Candidate Manager</NavLink>
                </li>
              </ul>
            </div>
          </div>

          

          {/* <div className="userloggedinfo">
            <div className="userinfo">
              <a href="https://activityboard.symstech.com/users/editOwn/" className="profileHandler" data-toggle="dropdown">
                <img alt='Default profile icon' src="https://activityboard.symstech.com/images/default-user.png" className="profilePicture" />
                <span className="username">Mohit Jeena </span>
                <i className="fa fa-caret-down" aria-hidden="true"></i>
              </a>
              <ul className="dropdown-menu">
                <li>
                  <a href="https://activityboard.symstech.com/users/editOwn/">
                    <span className="fa fa-fw fa-user"></span> My Profile            </a>
                </li>
                <li className="nav-header border">Help &amp; Support</li>
                <li className="border">
                  <a href="https://activityboard.symstech.com/auth/logout">
                    <i className="fa fa-fw fa-sign-out-alt" aria-hidden="true"></i> Sign Out            </a>
                </li>
              </ul>
            </div>
          </div>
          
          <ul className="headmenu">
            <li>
              <a href="https://activityboard.symstech.com/timesheets/showMy/">
                <span className="head-icon fa fa-clock-o"></span><span className="headmenu-label">My Timesheets</span>            </a>
            </li>
            <li>
              <a href="https://activityboard.symstech.com/calendar/showMyCalendar">
                <span className="head-icon fa-regular fa-calendar"></span><span className="headmenu-label">My Calendar</span>            </a>
            </li>
          
          </ul> */}
        </div>
      </div>
    </>
  )
}
export default Navbar;