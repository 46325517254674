import axios from "axios";
import Header from "../../header/Header";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
const JobRoleManager = () => {
  const [name, setJobRoleTitle] = useState("");
  const [updateFlag,setUpdateFlag] = useState(false)
  const [jobRoleId,setJobRoleId] = useState('')
  const ref = useRef(null);
  // add jobrole
  const handleJobSubmit = async (e) => {
    e.preventDefault();
    if(updateFlag===false){
    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/jobroles`,
      {
        name,
      }
    );
    window.location.reload();
    setJobRoleTitle("");
    }else{
      const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/jobroles`,
        {
          "id":jobRoleId,
          name
        }
      );
      setUpdateFlag(false)
      window.location.reload();
    }
  };

  // Jobrolelist
  const navigate = useNavigate();
  const [availJobRoles, setAvailJobRoles] = useState([]);

  const getJobRoles = async () => {
    const { data: jobroles } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/jobroles`
    );
    setAvailJobRoles(jobroles);
    localStorage.setItem("JOB_ROLES",JSON.stringify(jobroles))
    // console.log(jobroles);
  };

  const handleUpdate = async(jobRoles, id, e) =>{
    let uname = jobRoles[id-1].name;
    setJobRoleTitle(uname);
    setJobRoleId(id)
    setUpdateFlag(true)
    if(e.keyCode === 13){
      uname = jobRoles[id-1].name;
      setJobRoleTitle(uname);
    }
    ref.current.focus();
  };

  useEffect(() => {
    getJobRoles();
  }, []);

  return (
    <>
      <Header head="Job Role Manager" icon="fa-solid fa-user-tie" />
      <div className="maincontent">
        <div className="maincontentinner">
          <form
            className="formModal"
            method="post"
            style={{ minWidth: "250px" }}
            onSubmit={handleJobSubmit}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa-solid fa-square-plus"></i> Jobrole{" "}
                </h4>
                <input type="submit" value={`${updateFlag ? 'Update' : 'Add'}`} className="btn btn-primary" />
              </div>
            </div>

            <div className="">
              <div>
                <label htmlFor="jobrole-name">Jobrole Name</label>
                {
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setJobRoleTitle(e.target.value)}
                    className=""
                    id="jobrole-name"
                    ref={ref}
                  />
                }
              </div>
            </div>
          </form>
        </div>
      </div>

      {/* Jobrolelist */}
      <div className="maincontent mt-0">
        <div className="maincontentinner">
          <div
            id="allTicketsTable_wrapper"
            className="dataTables_wrapper no-footer"
          >
            <div className="top"></div>
            <table
              id="allTicketsTable"
              className="table table-bordered display dataTable no-footer"
              style={{ width: "100% " }}
              aria-describedby="allTicketsTable_info"
            >
              <colgroup>
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
                <col className="con1" />
                <col className="con0" />
              </colgroup>
              <thead>
                <tr>
                  <th
                    className="sorting"
                    tabIndex="0"
                    aria-controls="allTicketsTable"
                  >
                    #
                  </th>
                  <th
                    className="sorting"
                    tabIndex="1"
                    aria-controls="allTicketsTable"
                  >
                    Name
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="2"
                    aria-controls="allTicketsTable"
                  >
                    Created At
                  </th>
                  <th
                    className="status-col sorting"
                    tabIndex="3"
                    aria-controls="allTicketsTable"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {availJobRoles?.length > 0 ? (
                  <>
                    {availJobRoles.map((plan, index) => (
                      <tr className="odd">
                        <td
                         className="ticketModal"> {plan.id}
                        </td>
                        <td
                         className="ticketModal"> {plan.name}
                        </td>
                        <td
                          className="ticketModal"> {moment(plan.created_at).utc().format('ll @ LT')}
                        </td>

                        <td data-order="New">
                          <div className="ticketDropdown">
                            <a
                              className="f-left status label-info py-1 rounded-pill"
                              style={{ cursor: "pointer" }}
                              onClick={(e) => handleUpdate(availJobRoles, plan.id, e)}
                            >
                              <span className="text">Update</span>
                            </a>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </>
                ) : (
                  <tr><td>Nothing to show </td></tr>
                )}
              </tbody>
            </table>
            <div className="bottom">
              <div
                className="dataTables_info"
                id="allTicketsTable_info"
                role="status"
                aria-live="polite"
              >
                Showing 1 to 2 of 2 entries
              </div>
              <div className="dataTables_length" id="allTicketsTable_length">
                <label>
                  Show{" "}
                  <select
                    name="allTicketsTable_length"
                    aria-controls="allTicketsTable"
                    className=""
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>{" "}
                  entries
                </label>
              </div>
              <div
                className="dataTables_paginate paging_simple_numbers"
                id="allTicketsTable_paginate"
              >
                <a
                  href
                  className="paginate_button previous disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="0"
                  tabIndex="-1"
                  id="allTicketsTable_previous"
                >
                  Previous
                </a>
                <span>
                  <a
                    className="paginate_button current"
                    aria-controls="allTicketsTable"
                    data-dt-idx="1"
                    tabIndex="0"
                  >
                    1
                  </a>
                </span>
                <a
                  href
                  className="paginate_button next disabled"
                  aria-controls="allTicketsTable"
                  data-dt-idx="2"
                  tabIndex="-1"
                  id="allTicketsTable_next"
                >
                  Next
                </a>
              </div>
            </div>
            <div className="clear"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JobRoleManager;
