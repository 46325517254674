import axios from "axios";
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../../header/Header";
import $ from "jquery";
import moment from "moment";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import parse from 'html-react-parser';
import ReactChipInput from "react-chip-input";
import "../../navbar/navbar.css"
import { displayInHistorySection, fetchHistory } from "../ShowHistory";

const FetchCandidate = () => {
  const [chipData, setChipData] =useState();
  const navigate = useNavigate();

  //param for url routing
  const date=new Date();
  date.setMinutes(date.getMinutes() - date.getTimezoneOffset())
  const { interPlanId, candidateSearchWith } = useParams();

  const [search, setSearchFiled] = useState(candidateSearchWith);
  const [callOnSubmit, setCallOnSubmit] = useState('checked');

  const [name, setName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [candidateId, setCanIdNo] = useState("");

  const [finalCon, setFinalConText] = useState("");
  const [finalConDate, setFinalConDate] = useState("");

  const [intExeDiscution, setIntExDiscution] = useState("");
  const [effectiveTime, setEffectiveTime] = useState("");
  const [exactDateTime,setExactDateTime] = useState(date)

  const [intStatus, setIntStatus] = useState("");
  const [isFreshInView, setIsFreshInView] = useState(false);

  const [activityType,setActivityType] = useState("")
  const [activityStatus,setActivityStatus] = useState("")

  const [acceptanceLevel,setAcceptanceLevel]=useState(0);
  const [starPerformer,setStarPerformer] = useState(false)
   
  const [tags,setTags]=useState({chips: []});
  
  const [intLevelCom,setIntLevelCom]=useState(0);
  // useEffect(()=>{
  //   console.log("tagse",Object.values(tags));
  // },[tags])

  const loadinputToDefault = () => {
    // alert("reset")
    setName("");
    setContactNo("");
    setCanIdNo("");
    setFinalConText("");
    setFinalConDate("");
    setIntExDiscution("");
    setEffectiveTime("");
    setIntStatus("");
    setIsFreshInView(false);
   
    $("#finalDiscussionInit").hide();
    $("#interviewLevelClearedForm").hide();
    $("#maincontentinner").hide();
    $("#create_candiate_form").hide();
    $("#finalConclusion").hide();
    $("#interviewDiscutionForm").hide();
    $("#historyContainerInner").hide();
    $("performanceContainer").hide();
    $("#callButton").hide();
    $("#chipContainer").hide();
    $("#firstIntraction").html("");
    $("#oldApplications").html("");
    $("#historySection").html("");
  };

  const fetchCandidate = async (e) => {
    // init
    e.preventDefault();
    loadinputToDefault();
    const { data: candSearchRes } = await axios.get(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile/search/${search}`
    );
    // console.log("all data",candSearchRes);
    if (candSearchRes[0]?.id == undefined) {
      alert("1")
      loadinputToDefault()
      //Candidate Not found
      $("#create_candiate_form").show();
      
      $("#canName").focus();
      setContactNo(search);
      //Fresh interview
      setIsFreshInView(true);
      $('#performanceContainer').hide()
    } else {
      //Candidate history found
      setCanIdNo(candSearchRes[0]?.id);
      
      //Setup history section
      $("#maincontentinner").show();
      $("#historyContainerInner").show();
      $("#finalDiscussionInit").show(); 
      $('#performanceContainer').show();
      $("#chipContainer").show();
      $("#callButton").show();
      $("#interviewLevelClearedForm").show();
      $("#firstIntraction").html(
        "<li style='margin-left:0'><b>"+candSearchRes[0]?.name+" </b> (" +
        moment(candSearchRes[0]?.created_at)
          .utc()
          .format("ll @ LT") +
        ")<br />"+candSearchRes[0]?.address+"</li>"
      );

      //Find Interview appeared in
      fetchHistory(interPlanId,candSearchRes[0]?.id,search)

      const { data: inviewExeData } = await axios.get(
        `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/${interPlanId}/getExeData/${candSearchRes[0]?.id}`
      );
      console.log("inview data",candSearchRes);
      setIntLevelCom(inviewExeData[0].intRoundComlted)
      setAcceptanceLevel(inviewExeData[0].acceptanceLevel)
      setStarPerformer(inviewExeData[0].starPerformer)
      // setTags({...inviewExeData[0].tags})
      // alert(typeof(inviewExeData[0]?.id))
      if (inviewExeData[0]?.id == undefined) {
        //Fresh interview
        setIsFreshInView(true);
        //interview Discution
        $("#interviewDiscutionForm").show();
        $("#intDiscutionText").focus();
      } else {
        const { data: thisIntDiscutions } = await axios.get(
          `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/${interPlanId}/getDiscution/${candSearchRes[0]?.id}`
        );
        // console.log("int dis",thisIntDiscutions);
        if (thisIntDiscutions[0] != undefined) {
          thisIntDiscutions.forEach((thisIntDiscution) => {
            displayInHistorySection(
              thisIntDiscution,
              "historyFromApi"
            );
          });
        }

        if (inviewExeData[0]?.interLifeCycleStatus == "closed") {
          displayInHistorySection(inviewExeData,"finalConclusion")
        } else {
          //interview Discution
          $("#interviewDiscutionForm").show();
          $("#intDiscutionText").focus();
        }
        const handleReopen = () =>{
          $("#interviewDiscutionForm").show();
        }
        const reopenId = document.getElementById("intReopen")
        reopenId.addEventListener('click',handleReopen)
      }
    }
  };

  const handleInterviewDiscution = async (e) => {
    e.preventDefault();
    if (isFreshInView) {
      const interLifeCycleStatus = "init";
      const interCandStatus = "pending";

      const inExeHit = await axios.post(
        `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution`,
        {
          interLifeCycleStatus,
          interCandStatus,
          finalCon,
          interPlanId,
          candidateId,
        }
      );

      if (inExeHit.status == 200) {
        setIsFreshInView(false);
      } else {
        alert("error with data Interview Exicution");
        // console.log(inExeHit);
      }
    }

    const res = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/discution`,
      {
        interPlanId,
        candidateId,
        effectiveTime,
        intExeDiscution,
        activityStatus,
        activityType,
      }
    );
    if (res.status == 200) {
      const data = {intExeDiscution:intExeDiscution,activityStatus:activityStatus,effectiveTime:effectiveTime,activityType:activityType}
      displayInHistorySection(data,"historyFromApi");
      // console.log("data posted",intExeDiscution);
      $("#intDiscutionText").focus();
      $("#intDiscutionText").val("");
    }

    setIntExDiscution("")
    setActivityStatus("")
    setActivityType("")
    setExactDateTime(date)
  };

  const handleCreateCandidate = async (e) => {
    e.preventDefault();
    const canProfileHit = await axios.post(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`,
      {
        name,
        contactNo,
      }
    );

    if (canProfileHit.status === 200) {
      setCanIdNo(canProfileHit.data);

      //Setup history section
      $("#historySection").html("");
      $("#historyContainerInner").show();

      displayInHistorySection([name], "accCreated");

      $("#create_candiate_form").hide();

      //interview Discution
      $("#interviewLevelClearedForm").show();
      $("#interviewDiscutionForm").show();
      $("#intDiscutionText").focus();

      if (callOnSubmit === 'checked') {
        window.open("tel:" + contactNo, "_block");
      }
    }
  };

  const handleFinalConclusion = async (e) => {
    e.preventDefault();
    //show filan conclusion
    const interLifeCycleStatus = "closed";
    const interCandStatus = intStatus;

    const inExeHit = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution`,
      {
        interLifeCycleStatus,
        interCandStatus,
        finalCon,
        interPlanId,
        candidateId,
      }
    );

    if (inExeHit) {
      if (inExeHit.status === 200) {
        displayInHistorySection(finalCon,"case5");
        $("#finalConclusion").hide();
        $("#interviewLevelClearedForm").hide();
        $("#interviewDiscutionForm").hide();
        $("#historyContainerInner").hide();
        $("#finalDiscussionInit").hide();
        $("#performanceContainer").hide();
      }
    }
  };

  function handleDateChange(ev) {
    if (!ev.target['validity'].valid) return;
    const dt= ev.target['value'] + ':00';
    let getDate = dt.substring(0,10)
    let getTime = dt.substring(11,19)
    let getDateTime = getDate+" "+getTime
    setExactDateTime(dt);
    setEffectiveTime(getDateTime)
    // console.log("effective time",effectiveTime);
  }

  const handleStarPerformer = async() =>{
    // e.preventDefault();
    setStarPerformer(!starPerformer)
    const res = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution`,{
       "starPerformer":!starPerformer,
        interPlanId,
        candidateId,
      }
    )
  }
  
  const handleInterviewLevelCleared = async(e) =>{
    e.preventDefault();
    const addTags = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution/interviewRouCount`,
      {
        "interPlanId":interPlanId,
        "candidateId":candidateId,
        "intRoundComlted":intLevelCom,
      }
    );
  }

  const triggerPerformanceScore = async(e) =>{
    setAcceptanceLevel(e?.target?.value)
    e.preventDefault();
    const res = await axios.put(
        `${process.env.REACT_APP_API_ENDPOINT}/interviewExicution`,{
          "acceptanceLevel":e?.target?.value,
          interPlanId,
          candidateId,
        }
    );
  };

  useEffect(() => {
    // Update the document title using the browser API
    if (intStatus) {
      $("#finalConclusion").show();
      $("#finalConclusionText").focus();
    }
  });

 const addChip = async(value) => {
    const chips = tags.chips.slice();
    chips.push(value);
    setTags({ chips });
    // console.log("tagse",Object.values(chips));
    // console.log(tags.chips);
    // console.log(value);
    const addTags = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`,
      {
        "id":interPlanId,
        "name":name,
        "tags":Object.values(chips)
      }
    );
  };
  const removeChip = async(index) => {
    const chips = tags.chips.slice();
    chips.splice(index, 1);
    setTags({ chips });
    const removeTags = await axios.put(
      `${process.env.REACT_APP_API_ENDPOINT}/candidateprofile`,
      {
        "id":interPlanId,
        "name":name,
        "tags":(Object.values(chips)).toString()
      }
    );
  };


 let tagInput=document.querySelector(".styles_no-focus__2130q");
 if(tagInput != null)
 {
if(tags.chips.length <= 0)
{ 
     tagInput.placeholder="Enter Here...";
}
else{
 
  tagInput.placeholder=""; 
}}

useEffect(()=>{
  console.log("tags ds",tags);
},[tags])

  return (
    <>
      <Header head="Interview Room" icon="fa-solid fa-people-arrows" />
      <div className="maincontent d-flex justify-content-evenly align-items-start flex-wrap">
        <div className="col-md-6">
          <div className="maincontentinner">
            <form className="fetch-form container" onSubmit={fetchCandidate}>
              <label>Fetch Candidate</label>
              <div>
                <input
                  value={search}
                  autoComplete={"off"}
                  autoFocus
                  onChange={(e) => setSearchFiled(e.target.value)}
                  type="text"
                  placeholder="Search the candidate"
                  className="w-100"
                />
               <div className="d-flex justify-content-between align-items-start">
                <button
                  type="submit"
                  id="btnSearch"
                  className="btn btn-primary"
                >
                  {/* &#128270; */}
                  Search
                </button>

                <label id="callButton" className="text-primary me-1 fs-6" style={{display:"none"}} onClick={()=>window.open("tel:" + contactNo, "_block","popup")}>
                  Call
                </label>
                </div>
              </div>
            </form>
          </div>
  
          <div
            id="interviewDiscutionForm"
            className="maincontentinner"
            style={{ display: "none" }}
          >
            <form onSubmit={handleInterviewDiscution}>
              <div className="row">
                <div className="col">
              <h4 className="widgettitle title-light">
                    <i className="fa-solid fa-person-chalkboard"></i> Interview Discussion{" "}
                  </h4>
                  </div>
              </div>
              <div className="row d-flex flex-wrap">       
                    <div className="col-lg-4 col-md-6">
                      <label htmlFor="activitytype">Activity Type</label>
                      {
                        <select
                          value={activityType}
                          onChange={(e) => setActivityType(e.target.value)}
                          name="select-activitytype"
                          id="activitytype"
                        >
                          <option value="">None</option>
                          <option value="call">Call(headning)</option>
                          <option value="virtualMeet">Virtual Meet</option>
                          <option value="physicalMeet">Physical Meet</option>
                        </select>
                      }
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <label htmlFor="activitystatus">Activity Status</label>
                      {
                        <select
                          value={activityStatus}
                          onChange={(e) => setActivityStatus(e.target.value)}
                          name="select-activitystatus" 
                          id="activitystatus"
                        >
                          <option value="">None</option>
                          <option value="missed">Missed</option>
                          <option value="upcoming">Upcoming</option>
                        </select>
                      }
                    </div>
                    <div className="col-lg-4 col-md-6 ">
                  <label htmlFor="activitydate">Date</label>
                    <input
                       style={{width:"92%"}}
                      value={(exactDateTime || '').toString().substring(0, 16)}
                      type="datetime-local"
                      onChange={handleDateChange}
                      id="activitydate" 
                    />
                    </div>
                                 
              </div>
              <CKEditor
                editor={ClassicEditor}
                data={intExeDiscution}
                onChange={(event, editor) => {
                const data =editor.getData();
                setIntExDiscution(data)
                }}
                onReady={(editor) => {
                editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
                  );
                });
                }}
              />
              <br />
              <input className="btn btn-primary" value="Save" type="submit" />
            </form>
          </div>


          <div
            id="interviewLevelClearedForm"
            className="maincontentinner"
            style={{ display: "none" }}
          >
            <form onSubmit={handleInterviewLevelCleared}>
              <div className="row">
                <div className="col">
                  <h4 className="widgettitle title-light">
                    <i className="fa-solid fa-person-chalkboard"></i> Interview Leve(s) Complated{" "}
                  </h4>
                </div>
              </div>
              <div className="row d-flex flex-wrap">       
                <div className="col-lg-4 col-md-6">
                    <select
                      value={intLevelCom}
                      onChange={(e) => setIntLevelCom(e.target.value)}
                      name="select-intLevelCom"
                      id="intLevelCom"
                    >
                      <option value="">None</option>
                      <option value="1">1 One</option>
                      <option value="2">2 Two</option>
                      <option value="3">3 Three</option>
                    </select>
                </div>

                <div className="col-lg-4 col-md-6">
                  <input className="btn btn-primary" value="Save" type="submit" />
                </div>
              </div>
            </form>
          </div>
                  
          {/* Tags */}
          <div className="maincontentinner" id="chipContainer" style={{display:'none'}}>
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="widgettitle title-light my-auto">
              <i class="fa-sharp fa-solid fa-tags"></i> Tags{" "}
              </h4>
             
              <ReactChipInput
        chip-color="blue"
       classes="class1 class2 chipinput p-0 rounded-3"
        chips={tags.chips}
        onSubmit={(value) =>addChip(value)}
        onRemove={(index) => removeChip(index)}
      />
              </div>
              </div>


          <div className="maincontentinner" style={{ display: "none" }} id="performanceContainer">
            <div className="d-flex justify-content-between align-items-center">
              <h4 className="widgettitle title-light my-auto">
                <i className="fa-solid fa-person-running"></i> Performance{" "}
              </h4>
              </div>
            
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <input
                      className="btn btn-outline-primary my-auto me-2"
                      checked={starPerformer}
                      onChange={handleStarPerformer}
                      type="checkBox"
                       id="starperformer"
                  /> 
                  <label htmlFor="starperformer">Star Performer</label>
                </div>
                <div className="d-flex justify-content-between">
              
                <input type="range" min="0" max="5" style={{accentColor:"primary"}} step="1" onMouseUp={(e)=>triggerPerformanceScore(e)} value={acceptanceLevel} onChange={(e) =>setAcceptanceLevel(e.target.value)} />
                <h2 className="ms-2">{acceptanceLevel}</h2>
                </div>
              </div>
          </div>

          <form
            className="maincontentinner"
            id="finalConclusion"
            onSubmit={handleFinalConclusion}
            style={{ display: "none" }}
          >
            <div className="row">
              <div className="col-md-12 d-flex justify-content-between align-items-start">
                <h4 className="widgettitle title-light">
                  <i className="fa fa-rocket"></i> Conclusion{" "}
                </h4>
                <button className="btn btn-primary" type="button submit">
                  {" "}
                  {intStatus.toUpperCase() + " Candidate"}{" "}
                </button>
              </div>
            </div>
            <p>Final Conclusion</p>
            <CKEditor
              editor={ClassicEditor}
              data={finalCon}
              onChange={(event, editor) => {
              const data =editor.getData();
              setFinalConText(data)
              }}
              onReady={(editor) => {
                editor.editing.view.focus()
                editor.editing.view.change((writer) => {
                writer.setStyle(
                  "height",
                  "200px",
                  editor.editing.view.document.getRoot()
                  );
                });
                }}
              />
          </form>

          <div className="maincontentinner" style={{ display: "none" }} id="finalDiscussionInit">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <h4 className="widgettitle title-light my-auto">
                <i className="fa-solid fa-person-running"></i> Status{" "}
              </h4>
            
              <div>
                <button
                  className="btn btn-primary me-2"
                  onClick={() => setIntStatus("pass")}
                >
                  {" "}
                  Qualify{" "}
                </button>
                <button
                  className="btn btn-primary "
                  onClick={() => setIntStatus("fail")}
                >
                  {" "}
                  Dis-Quality{" "}
                </button>
              </div>
            </div>
          </div>
       
          <div
            className="maincontentinner"
            id="create_candiate_form"
            style={{ display: "none" }}
          >
            <form onSubmit={handleCreateCandidate}>
              <div className="row">
                <div className="col-md-12 d-flex justify-content-between align-items-start">
                  <h4 className="widgettitle title-light">
                    <i className="fa fa-rocket"></i> Create Candidate{" "}
                  </h4>
                  <div className="d-flex align-items-center">
                    <input
                      className="btn btn-primary me-3"
                      value="Create"
                      type="submit"
                    />
                    <input
                      className="btn btn-outline-primary me-3"
                      value="Call"
                      type="checkBox"
                      id='callOnSubmit'
                      checked={callOnSubmit}
                      onChange={(e) => setCallOnSubmit(!callOnSubmit)}
                    /> 
                    <label for='callOnSubmit' className="fs-6">Call</label>
                  </div>
                </div>
              </div>

              <div className="d-flex flex-wrap">
                <div className="col-md-6">
                  <label htmlFor="Name">Name</label>
                  <input
                    value={name}
                    type="text"
                    placeholder="Name"
                    id="canName"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>

                <div className="col-md-6">
                  <label htmlFor="Contact">Contact No</label>
                  <input
                    value={contactNo}
                    type="text"
                    placeholder="Contact no"
                    id="contactNo"
                    onChange={(e) => setContactNo(e.target.value)}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <div className="maincontentinner col-md-6" id="historyContainer">
          <section>
            <div className="col-md-12 d-flex justify-content-between align-items-start">
              <h4 className="widgettitle title-light">
                <i className="fa-sharp fa-solid fa-clock-rotate-left"></i> History{" "}
              </h4>
              <span
                    className="status label-info py-1"
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate(`/candidatelist/${interPlanId}`)}
                  >Candidate List</span>
            </div>
            {
              <div className="col-12 ms-4" id="historyContainerInner" style={{ display: "none" }}>
                <ul
                  id="firstIntraction"
                  className="mb-1 ps-0 m-0"
                  style={{ listStyle: "none" }}
                ></ul>
                <hr
                  style={{
                    border: "2px dotted rgb(181, 171, 171)",
                    marginTop: " 5px",
                  }}
                />
                <table className="border border-1 w-100 mt-2">
                  <thead className="border border-1 ">
                    <tr className="border border-1 text-center">
                      <th className="border border-1">Date&Time</th>
                      <th className="border border-1">interPlanId</th>
                      <th className="border border-1">final conclusion</th>
                      <th className="border border-1">fcDate</th>
                    </tr>
                  </thead>
                  <tbody
                    id="oldApplications"
                    className="mb-1 border border-1 text-center"
                  ></tbody>
                </table>
                <hr
                  style={{
                    border: "2px dotted rgb(181, 171, 171)",
                    marginTop: " 5px",
                  }}
                />
                <b>Previous Interview Discussion::</b>
                <ol id="historySection" className="mb-1 ms-3 mt-2"></ol>
              </div>
            }
          </section>
        </div>
      </div>
    </>
  );
};
export default FetchCandidate;
