import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Header from '../../header/Header';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import InterPlanTable from '../InterPlanTable';
const CreateInterviewPlan = () => {
    const basicScreeningQsId = "6364c5397746c1835ad1e02e";
    // for states through api calls
    const [availDepartments, setAvailDepartments] = useState([]);
    const [availJobRoles, setAvailJobRoles] = useState([]);
    const [availResumes, setAvailResumes] = useState([]);

    // for selected states
    const [departmentId, setdepartmentId] = useState("");
    const [jobRoleId, setjobRoleId] = useState("");
    const [jobPayRoleType, setjobPayRoleType] = useState();
    const [resumeCollectionId, setresumeCollectionId] = useState("");
    const [interMode, setInterMode] = useState("");
    // const [basicScreeningQuestionId, setbasicScreeningQuestionId] = useState("");
    const [interPostingDate, setDate] = useState();
    const [intPlanComment, setIntPlanComment] = useState("");
    
    // useEffect(()=>{
    //     console.log("comment",intPlanComment);
    // },[intPlanComment])
    // fetch departments
    const getDepartmentsAPI = async () => {
        const { data: departments } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/departments`);
        setAvailDepartments(departments);
        localStorage.setItem("ALL_DEPARTMENT",JSON.stringify(departments))
        // console.log(availDepartments);
    }
    const getDepartments = () =>{
        const tempDepartmentData = JSON.parse(localStorage.getItem("ALL_DEPARTMENT"))
        tempDepartmentData?.length > 0 ? setAvailDepartments(tempDepartmentData) : getDepartmentsAPI()
    }

    const getJobRolesAPI = async () => {
        const { data: jobroles } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/jobroles`);
        setAvailJobRoles(jobroles);
        localStorage.setItem("JOB_ROLES",JSON.stringify(jobroles))
        // console.log(jobroles);
    }

    const getJobRoles = () =>{
        const tempJobRolesData = JSON.parse(localStorage.getItem("JOB_ROLES"))
        tempJobRolesData?.length > 0 ? setAvailJobRoles(tempJobRolesData) : getJobRolesAPI()
    }

    const getresumeSourcesAPI = async () => {
        const { data: resumes } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/resumesource`);
        setAvailResumes(resumes);
        localStorage.setItem("RESUME_SOURCE",JSON.stringify(resumes))
        // console.log(resumes);
    }

    const getresumeSources = () =>{
        const tempResumeData = JSON.parse(localStorage.getItem("RESUME_SOURCE"))
        tempResumeData?.length > 0 ? setAvailResumes(tempResumeData) : getresumeSourcesAPI()
      }

    // const getBasicScreening = async()=>{
    //     const {data: screeningQuestions} = await axios.get(`${process.API_URL}/basicscreening`) ;
    //     setbasicScreeningQuestionId(screeningQuestions);
    //     console.log(screeningQuestions);
    // }

    useEffect(() => {
        getDepartments();
        getJobRoles();
        getresumeSources();
        // getBasicScreening();
    }, []);

    // form submit
    const handleInterPlanSubmit = async (e) => {
        e.preventDefault();
        // console.log(`Department is ${departmentId}, jobrole is ${jobRoleId}, resume coll source is ${resumeCollectionId}`);
        console.log(departmentId, resumeCollectionId, jobRoleId, basicScreeningQsId);
        // submit
        const res = await axios.post(`${process.env.REACT_APP_API_ENDPOINT}/interviewplans`, {
            departmentId,
            resumeCollectionId,
            jobRoleId,
            jobPayRoleType,
            basicScreeningQsId,
            interPostingDate,
            interMode,
            intPlanComment,
        });
        window.location.reload();
    }
    // InterviewPlanList

    // navigator
    const navigate = useNavigate();

    // states for plans 
    // const [availInterplans, setAvailInterPlans] = useState([]);

    // const getInterviewPlans = async () => {
    //     const { data } = await axios.get(`${process.env.REACT_APP_API_ENDPOINT}/interviewplans`);
    //     setAvailInterPlans(data);
    //     // console.log("candidate data",data);
    // }

    // useEffect(() => {
    //     getInterviewPlans();
    // }, []);
    return (
        <>
            <Header head="New Interview Plan" icon="fa-solid fa-people-roof" />

            <div className="maincontent">
                <div className="maincontentinner">
                    <form className="formModal" method="post" style={{ minWidth: "250px" }} onSubmit={handleInterPlanSubmit} >
                        <div className="row">
                            <div className="col-md-12 d-flex justify-content-between align-items-start">
                                <h4 className="widgettitle title-light">
                                    <i className="fa-solid fa-plus"></i> Create an interview plan </h4>
                                <input type="submit" value="Submit" className="btn btn-primary" />
                            </div>
                        </div>

                        <div className='d-flex flex-wrap'>
                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label htmlFor="departments">
                                    Department
                                </label>
                                {
                                    <select
                                        value={departmentId}
                                        onChange={(e) => setdepartmentId(e.target.value)}
                                        name="select-department"
                                        className="span11"
                                        id="departments">
                                        {
                                            availDepartments.length > 0 ?
                                                <>
                                                    <option>None</option>
                                                    {availDepartments.map((dep, index) => (
                                                        <option
                                                            value={dep.id}
                                                            key={index} >
                                                            {dep.name}
                                                        </option>
                                                    ))}
                                                </>
                                                :
                                                ""
                                        }
                                    </select>
                                }
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label htmlFor="jobroles">
                                    Job role
                                </label>
                                {
                                    <select
                                        value={jobRoleId}
                                        onChange={(e) => setjobRoleId(e.target.value)}
                                        name="select-jobrole"
                                        className="span11"
                                        id="jobroles">
                                        {
                                            availJobRoles.length > 0 ?
                                                <>
                                                    <option>None</option>
                                                    {availJobRoles.map((jrole, index) => (
                                                        <option
                                                            value={jrole.id}
                                                            key={index}>
                                                            {jrole.name}
                                                        </option>
                                                    ))}
                                                </>
                                                :
                                                ""
                                        }
                                    </select>
                                }
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label htmlFor="jobpayroles">
                                    Pay-Role Type
                                </label>
                                {
                                    <select
                                        value={jobPayRoleType}
                                        onChange={(e) => setjobPayRoleType(e.target.value)}
                                        name="select-jobpayrole"
                                        className="span11"
                                        id="jobpayroles">
                                        <option value="none">None</option>
                                        <option value="internship">Internship</option>
                                        <option value="contract">Contract</option>
                                        <option value="permanent">Permanent</option>
                                    </select>
                                }
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label htmlFor="resumes">
                                    Resume source
                                </label>

                                {
                                    <select
                                        value={resumeCollectionId}
                                        onChange={(e) => setresumeCollectionId(e.target.value)}
                                        name="select-resume"
                                        className="span11"
                                        id='resumes'
                                    >
                                        {
                                            availResumes.length > 0 ?
                                                <>
                                                    <option>None</option>
                                                    {availResumes.map((resum, index) => (
                                                        <option
                                                            value={resum.id}
                                                            key={index}>
                                                            {resum.name}
                                                        </option>
                                                    ))}
                                                </>
                                                :
                                                ""
                                        }
                                    </select>
                                }
                            </div>
                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label
                                    htmlFor="date">
                                    Date{" "}
                                </label>

                                <input
                                    value={interPostingDate}
                                    onChange={(e) => setDate(e.target.value)}
                                    type="date"
                                    className='hasDatepicker'
                                    id='date'
                                />
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label htmlFor="modes">
                                    Interview Mode
                                </label>

                                <select
                                    value={interMode}
                                    onChange={(e) => setInterMode(e.target.value)}
                                    name="interview-mode"
                                    className="span11"
                                    id='modes'>

                                    <option value="none">None</option>
                                    <option value="online">Online</option>
                                    <option value="offline">Offline</option>
                                    <option value="hybrid">Hybrid</option>
                                </select>
                            </div>

                            <div className='col-lg-3 col-md-4 col-sm-6'>
                                <label
                                    htmlFor="interviewplancomt">
                                    Interview Plan Comment
                                </label>

                                <input
                                    value={intPlanComment}
                                    onChange={(e) => setIntPlanComment(e.target.value)}
                                    type="text"
                                    id='interviewplancomt'
                                />
                            </div>

                        </div>
                    </form>
                </div>
            </div>
            <InterPlanTable />
        </>
    )
}

export default CreateInterviewPlan;
