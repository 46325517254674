import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function SearchBarSuggestion({ txtInput, data }) {
  const [searchSuggestion, setSearchSuggestion] = useState([]);

  const handleSearchResult = () => {
    let matches = [];
    if (txtInput.length > 0) {
      matches = data.filter((user) => {
        const regex = new RegExp(`${txtInput}`, "gi");
        return user.deptName.match(regex);
      });
    }
    // console.log("matjces", matches);
    setSearchSuggestion(matches);
    console.log("search sugg",searchSuggestion);
  };

  useEffect(() => {
    handleSearchResult();
  }, [txtInput]);

  return (
    <>
      {searchSuggestion.length > 0 && (
        <div style={{ border: "1px solid grey", padding: "10px",marginBottom:'5px',borderRadius:'2px' }}>
          {searchSuggestion.map((items, index) => {
            return (
              <div key={index} style={{ cursor: "pointer" }}>
                {items.deptName}
              </div>
            );
          })}
        </div>
      )}
    </>
  );
}

export default SearchBarSuggestion;
